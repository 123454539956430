import style from "./000_Content.module.css"

const IBPS_PO_MT=()=>{
    document.title="IBPS Probationary Officer / Management Trainee Admit Card Card | Job For Sarkari"
    return(
        <div>
            <div id={style.page}> 
             <h1>IBPS Probationary Officer / Management Trainee, Mains Admit Card & Score Card (2024)</h1>
             <h2><b style={{color:"red"}}>Post-Name :-</b> IBPS Probationary Officer / Management Trainee PO / MT Recruitment 2024</h2>
             <h2><b style={{color:"red"}}>Starting Date :- </b>01/08/2024</h2>
             <h2><b style={{color:"red"}}>Last Date :- </b>28/08/2024</h2>
             <h2 style={{paddingTop:"20px"}}> <b style={{color:"red"}}>Important Information :-</b>The IBPS Common Recruitment Process for Probationary Officers and Management Trainees in Participating Banks XIV Exam 2024 is administered by the Institute of Banking Personal Selection. Candidates may get the results and mains admit card for the IBPS Probationary Officer/Management Trainee 14th Examination. For information on recruitment eligibility, post details, selection process, age restriction, salary rate, and other details, see the Common Recruitment Process Clerks in Participating Banks notice.</h2>
             <div  id={style.aa} style={{border:"none"}}><h1>Www.JobForSarkari.Com</h1></div>
             
             <div>
                <div id={style.p1}>
                    <h1>IBPS PO/ MT XIV Recruitment (2024)</h1>
                    <h3>(Institute Of Banking Personnel Selection(IBPS))</h3>
                    <h2>Post Name – IBPS PO/ MT XIV Recruitment (2024)</h2>
                    
                </div>
                <div id={style.p2}>
                    <h1>Total Posts - 4455</h1>
                </div>
                <div id={style.p3}>
                    <div>
                        <h1>Important Date</h1>
                        <p><li><b>Start Date :-</b> 01/08/2024</li></p>
                        <p><li><b>Last Date :-</b> 28/08/2024</li></p>
                        <p><li><b>Fee Payment Last Date :-</b> 28/08/2024</li></p>
                        <p><li><b>Preliminary Admit Card :-</b> 11/10/2024</li></p>
                        <p><li><b>Preliminary Exam Date :-</b> October 2024</li></p> 
                        <p><li><b>Preliminary Result :-</b> 21/11/2024</li></p> 
                        <p><li><b>Mains Exam Admit Card :-</b> 25/11/2024</li></p>
                        <p><li><b>Preliminary Exam Date :-</b> November 2024</li></p> 
                        <p><li><b>Pre Score Card :-</b> 27/11/2024</li></p> 
                    </div>
                    <div>
                        <h1>Application Fee</h1>  
                        <p><li><b>General / OBC / EWS :-</b> 850/-</li></p>
                        <p><li><b>SC/ST :-</b> 175/-</li></p> 
                        <p><li><b>PH(Disability) :-</b> 175/-</li></p>  
                        <p style={{paddingTop:"20px"}}> <b style={{color:"red"}}>NOTE :-</b><b> Candidates can pay their Exam Fee through Credit Card / Debit Card / Net Banking Fee Mode.</b></p> 
                    </div>
                </div>
                <div id={style.p3}>
                <div>
                        <h1>Job Location</h1>
                        <p style={{padding:"0",textAlign:"center"}}>All Over India</p>          
                        
                    </div>
                    <div>
                        <h1>Age Limit</h1>  
                        <p><li><b>Minimum Age :-</b>20 Years</li></p> 
                        <p><li><b>Maximum Age :-</b>30 Years</li></p> 
                       <p><li>Additional age relaxation as per recruitment guidelines, please read the  official notification.</li></p>            
                    </div>
                </div>
                <div id={style.p5}> <h1 style={{color:"red"}}>Eligibility Qualification Details </h1></div>
                <div className={style.p4}> <div className={style.q1}> <h5>Post Name</h5></div> <div className={style.q2}> <h5>Total Post</h5></div> <div className={style.q3}> <h5>Eligibility Criteria</h5></div></div>
                <div className={style.p4}>
                    <div className={style.q1}> IBPS PO / MT</div> 
                    <div className={style.q2}> 4455</div> 
                    <div className={style.q3}> <li></li> Candidates having Bachelor Degree (Graduation Degree) in any Stream from a recognized University in India, will be eligible for this post.</div>
                    
                </div>
                
                <div id={style.p5}>
                    <h1>Vacancy Details for IBPS PO 14th Recruitment (2024) </h1>
                     
                     <div>
                     <h1>Post Name :- IBPS Probationary Officer / Management Trainee Recruitment (2024).</h1>
                      <h2>State Wise Vacancy Details 2023 :-</h2>
                      
                     <h3><li><b>Bank Of India </b> :– 885 Posts</li></h3>
                     <h3><li><b>Canara Bank</b> :– 750 Posts</li></h3>
                     <h3><li><b>Central Bank of India </b> :– 2000 Posts</li></h3>
                     <h3><li><b>Indian Overseas Bank</b> :– 260 Posts</li></h3>
                     <h3><li><b>Punjab National Bank</b> :– 200 Posts</li></h3>
                     <h3><li><b>Punjab & Sind Bank</b> :– 360 Posts</li></h3>  
                     </div>
                </div>
                <div id={style.p6}>
                    <h1>Please read all the information carefully before filling the form</h1>
                </div>
                <div id={style.p7}>
                    <h1>Requirment Document :-</h1>
                    <h3><li>Left Thumb Impression</li></h3>
                    <h3><li>Passport Size Photo</li></h3>
                    <h3><li>Signture</li></h3>
                    <h3><li>Aadhar Card</li></h3>
                    <h3><li>Email Id</li></h3>
                    <h3><li>Phone Number</li></h3>
                </div>
                <div id={style.p8}>
                    <div><h1 style={{color:"blue"}}>Examination Mode :- Online Computer Base Test(CBT)</h1></div>
                    <div><h1 style={{color:"green"}}>Here Some Important Links</h1></div>
                </div>
                <div id={style.p9}> 
                <div>
                        <div><h2>Pre Score Card</h2></div>
                        <div><a href="https://ibpsonline.ibps.in/crppo14jul24/opes_nov24/login.php?appid=4b69f94d2baa42f75cef4b6af5f3a517" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    <div>
                        <div><h2>Admit Card (Mains)</h2></div>
                        <div><a href="https://ibpsonline.ibps.in/crppo14jul24/oecla_nov24/login.php?appid=7fbb66d8ef02416a0b6925467b2baa8b" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    <div>
                        <div><h2>Pre Result</h2></div>
                        <div><a href="https://ibpsonline.ibps.in/crppo14jul24/res1a_nov24/login.php?appid=dbad66c45d0a300d72fa618cbb4443c8" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    <div>
                        <div><h2>Pre Admit Card</h2></div>
                        <div><a href="https://ibpsonline.ibps.in/crppo14jul24/oecla_oct24/downloadClose.php" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    <div>
                        <div><h2>Apply Online</h2></div>
                        <div><a href="https://ibpsonline.ibps.in/crppo14jul24/" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    
                    <div>
                        <div><h2>Official Notification</h2></div>
                        <div><a href="https://doc.sarkariresults.org.in/upload/Detailed-Notification_CRP-PO-XIV_final-1.pdf" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    <div>
                        <div><h2>Syllabus &Pattern</h2></div>
                        <div><a href="https://www.careerpower.in/ibps-po-syllabus-exam-pattern.html" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    <div>
                        <div><h2>Privious Year Question</h2></div>
                        <div><a href="https://www.careerpower.in/ibps-po-previous-year-question-paper.html" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    <div>
                        <div><h2>How To Apply</h2></div>
                        <div><a href="https://www.youtube.com/watch?v=pF7DvMRwpas" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    <div>
                        <div><h2>Resize Images</h2></div>
                        <div><a href="https://www.iloveimg.com/resize-image" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    <div>
                        <div><h2>JPG To PDF</h2></div>
                        <div><a href="https://www.ilovepdf.com/jpg_to_pdf" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    <div>
                        <div><h2>Official Website</h2></div>
                        <div><a href="https://www.ibps.in/" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    <div>
                        <div><h2>Follow Our Pages</h2></div>
                        <div><a href="https://www.facebook.com/profile.php?id=100092973634766" target="_blank"><h2>@Facebook</h2></a><p style={{paddingLeft:"15px"}}></p><a href="https://www.instagram.com/jobforsarkari/" target="_blank"><h2>@Instagram</h2></a></div>
                    </div>
                    <div>
                        <div><h2>Join With Us</h2></div>
                        <div><a href="https://whatsapp.com/channel/0029VatEOgj0wajl9779pA0f" target="_blank"><h2>@Whatsapp</h2></a><p style={{paddingLeft:"15px"}}></p><a href="https://web.telegram.org/a/#-4725277915" target="_blank"><h2>@Telegram</h2></a></div>
                    </div>
                </div>
             </div>
            
        </div>
        <div id={style.ins}>
            <h1>Www.JobForSarkari.Com</h1>
            <h2 style={{color:"green"}}>welcome to our website</h2>
            <p>Our website provide you official site of related job</p>
            <p>Our website does not charge for any service</p>
            <p>We request that you must read all the instructions before filling</p>
        </div>
        </div>
    )
}

export default IBPS_PO_MT