import style from "./000_Content.module.css"

const A0_Voter_Id_Card=()=>{
    document.title="New Voter ID Registration, Check Application Status, Modify Voter Id Card | Job For Sarkari"
    return(
        <div>
            <div id={style.page}> 
             <h1>Apply New Voter ID Registration, Check Application Status, Modify Voter Id Card Online</h1>
             <h2><b style={{color:"red"}}>Post-Name :-</b> Apply New Voter ID Card, Check Application Status,Modify Voter Id Card Online</h2>
             <h2><b style={{color:"red"}}>Starting Date :- </b>NA</h2>
             <h2><b style={{color:"red"}}>Last Date :- </b>NA</h2>
             <h2 style={{paddingTop:"20px"}}> <b style={{color:"red"}}>Important Information :-</b>The National Voter Service Portal (NVSP) invites all eligible individuals to apply online for the registration of new voter IDs throughout India. Subscribers to the portal can request changes, updates, and corrections to their old voter IDs, search for their name in the election roll, and many other services.</h2>
             <div  id={style.aa} style={{border:"none"}}><h1>Www.JobForSarkari.Com</h1></div>
             
             <div>
                <div id={style.p1}>
                    <h1> New Voter ID Card, Check Application Status,Modify Voter Id Card</h1>
                    <h3>(Election Commission of India National Voter Services (NVSP))</h3>
                    <h2>Apply New Voter ID Card, Check Application Status,Modify Voter Id Card</h2>
                    
                </div>
                <div id={style.p2}>
                    <h1>Www.JobForSarkari.Com</h1>
                </div>
                <div id={style.p3}>
                    <div>
                        <h1>Important Date</h1>
                        <p><li><b>Scheme Started Date :-</b> NA</li></p>
                        
                        <p><li><b>Last Date For Apply :-</b> NA</li></p>
                       
                    </div>
                    <div>
                        <h1>Application Fee</h1>        
                        <p><li><b>New Voter Id Card :-</b> 0/-/-</li></p>   
                        <p><li><b>Correction in Details  :- </b> 25 - 50/-</li></p>  
                        <p><li>Online corrections via the National Voter Service Portal (NVSP) or mobile apps are often free.</li></p>
                        <p style={{paddingTop:"20px"}}> <b style={{color:"red"}}>NOTE :-</b><b> You can pay their Charges / Fee through Credit Card / Debit Card / Net Banking Fee Mode.</b></p> 
                    </div>
                </div>
                <div id={style.p3}>
                <div>
                        <h1>eligible for Voter Id card</h1>
                        <p><li><b>Nationality :-</b>Indian</li></p>
                        <p><li><b>Place of Birth  :-</b>India</li></p>
                        <p><li>Non-resident Indians (NRIs) can also apply, provided they fulfill certain conditions set by the Election Commission of India.</li></p>
                    </div>
                    <div>
                        <h1>Age Limit <h5 style={{color:"red"}}>Age Limit</h5></h1>  
                        <p><li><b>Minimum Age :-</b>18 Years</li></p> 
                        <p><li><b>Maximum Age :-</b>NA</li></p> 
                                  
                    </div>
                </div>
                <div id={style.p6}>
                    <h1>Please read all the information carefully before filling the form</h1>
                </div>
                <div id={style.p7}>
                    <h1>Respective Documents Needed For Respective Modification :-</h1>
                    <h3><li>Passport Size Photo</li></h3>
                    <h3><li>Signture</li></h3>
                    <h3><li>Email Id</li></h3>
                    <h3><li>Phone Number</li></h3>
                </div>
                <div id={style.p8}>
                    <div><h1 style={{color:"blue"}}>Needed Mobile Or Laptop To Download, Modify Aadhar Card</h1></div>
                    <div><h1 style={{color:"green"}}>Here Some Important Links</h1></div>
                </div>
                <div id={style.p9}>
                    <div>
                        <div><h2> New Voter ID Card Registration</h2></div>
                        <div><a href="https://voters.eci.gov.in/" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    <div>
                        <div><h2>Online Correction Voter ID Card</h2></div>
                        <div><a href="https://voters.eci.gov.in/" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    <div>
                        <div><h2>Application Status</h2></div>
                        <div><a href="https://voters.eci.gov.in/" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    <div>
                        <div><h2>New Voter ID Card Registration (NRI)</h2></div>
                        <div><a href="https://voters.eci.gov.in/" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    <div>
                        <div><h2>Resize Images</h2></div>
                        <div><a href="https://www.iloveimg.com/resize-image" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    <div>
                        <div><h2>JPG To PDF</h2></div>
                        <div><a href="https://www.ilovepdf.com/jpg_to_pdf" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    <div>
                        <div><h2>Official Website</h2></div>
                        <div><a href="https://voters.eci.gov.in/" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    <div>
                        <div><h2>Follow Our Pages</h2></div>
                        <div><a href="https://www.facebook.com/profile.php?id=100092973634766" target="_blank"><h2>@Facebook</h2></a><p style={{paddingLeft:"15px"}}></p><a href="https://www.instagram.com/jobforsarkari/" target="_blank"><h2>@Instagram</h2></a></div>
                    </div>
                    <div>
                        <div><h2>Join With Us</h2></div>
                        <div><a href="https://whatsapp.com/channel/0029VatEOgj0wajl9779pA0f" target="_blank"><h2>@Whatsapp</h2></a><p style={{paddingLeft:"15px"}}></p><a href="https://web.telegram.org/a/#-4725277915" target="_blank"><h2>@Telegram</h2></a></div>
                    </div>
                </div>
             </div>
            
        </div>
        <div id={style.ins}>
            <h1>Www.JobForSarkari.Com</h1>
            <h2 style={{color:"green"}}>welcome to our website</h2>
            <p>Our website provide you official site of related job</p>
            <p>Our website does not charge for any service</p>
            <p>We request that you must read all the instructions before filling</p>
        </div>
        </div>
    )
}

export default A0_Voter_Id_Card