import style from "./000_Content.module.css"

const UPSC_Engineering_Services=()=>{
    document.title="UPSC Engineering Services Result | Job For Sarkari"
    return(
        <div>
            <div id={style.page}> 
             <h1>UPSC Engineering Services Examination, Publish Final Result and Publish Marks (2024)</h1>
             <h2><b style={{color:"red"}}>Post-Name :-</b> UPSC Engineering Services Examination Online Form 2024</h2>
             <h2><b style={{color:"red"}}>Starting Date :- </b> 06/09/2023</h2>
             <h2><b style={{color:"red"}}>Last Date :- </b> 26/09/2023 Till 06 PM</h2>
             <h2 style={{paddingTop:"20px"}}> <b style={{color:"red"}}>Important Information :-</b> The Engineering Services Engineering Examination 2024 has been made public by the Union Public Service Commission (UPSC). Candidates who are enrolled and have a vacancy can download their final results along with their marks. For more on the recruitment eligibility, post details, selection process, age restriction, salary range, and other details, see the notice.</h2>
             <div  id={style.aa} style={{border:"none"}}><h1>Www.JobForSarkari.Com</h1></div>
             
             <div>
                <div id={style.p1}>
                    <h1>UPSC Engineering Services Examination (2024)</h1>
                    <h3>(Union Public Service Commission(UPSC))</h3>
                    <h2>Post Name – UPSC Engineering Services Examination Online Form</h2>
                    
                </div>
                <div id={style.p2}>
                    <h1>Total Posts - 167</h1>
                </div>
                <div id={style.p3}>
                    <div>
                        <h1>Important Date</h1>
                        <p><li><b>Start Date :-</b>06/09/2023</li></p>
                        <p><li><b>Last Date :-</b> 29/09/2023 Till 06 PM</li></p>
                        <p><li><b>Fee Payment Last Date :-</b> 29/09/2023</li></p>
                        <p><li><b>Modification OTR date :-</b> 03/10/2023</li></p> 
                        <p><li><b>Pre Admit Date :-</b>09/02/2024</li></p>
                        <p><li><b>Pre Exam Date :-</b> 18/02/2024</li></p>
                        <p><li><b>Pre Result Date :-</b>28/03/2024</li></p>
                        <p><li><b>Mains Exam Admit Date :-</b>14/06/2024</li></p>
                        <p><li><b>Mains Result Date :-</b>30/07/2024</li></p>
                        <p><li><b>DAF Form Date :-</b>12-21 August 2024</li></p>
                        <p><li><b>Final Result Date :-</b>22/11/2024</li></p>
                        <p><li><b>Final Marks Date :-</b>28/11/2024</li></p>
                    </div>
                    <div>
                        <h1>Application Fee</h1>  
                        <p><li><b>General / OBC / EWS :-</b> 200/-</li></p>
                        <p><li><b>SC/ST :-</b> No Fee</li></p> 
                        <p><li><b>PH(Disability) :-</b> No Fee</li></p> 
                        <p><li><b>All Female :-</b> No Fee</li></p> 
                        <p style={{paddingTop:"20px"}}> <b style={{color:"red"}}>NOTE :-</b><b> Candidates can pay their Exam Fee through Credit Card / Debit Card / Net Banking Fee Mode.</b></p> 
                    </div>
                </div>
                <div id={style.p3}>
                <div>
                        <h1>Job Location</h1>
                        <p style={{padding:"0",textAlign:"center"}}>All Over India</p>          
                        
                    </div>
                    <div>
                        <h1>Age Limit <h5 style={{color:"red"}}>Age Limit till 01/01/2024</h5></h1>  
                        <p><li><b>Minimum Age :-</b> 21 Years.</li></p> 
                        <p><li><b>Maximum Age :-</b>  30 Years.</li></p> 
                       <p><li>Additional age relaxation as per UPSC recruitment guidelines, please read the  official notification.</li></p>            
                    </div>
                </div>
                <div id={style.p5}> <h1 style={{color:"red"}}>Eligibility Qualification Details </h1></div>
                <div className={style.p4}> <div className={style.q1}> <h5>Post Name</h5></div> <div className={style.q2}> <h5>Total Post</h5></div> <div className={style.q3}> <h5>Eligibility Criteria</h5></div></div>
                <div className={style.p4}>
                    <div className={style.q1}> UPSC Engineering Services</div> 
                    <div className={style.q2}> 167</div> 
                    <div className={style.q3} style={{display:"inline"}}> <li>Passed / Appearing Engineering Degree in Related Trade / Stream</li> <li> Must Read the Full Notification For More Details.</li></div>
                </div>
                <div id={style.p5}>
                    <h1>UPSC Engineering Services Examination Online Form (2024) </h1>
                     
                     <div>
                     <h1>Post Name :- UPSC Engineering Services Examination 2024.</h1>
                      <h2>Post Wise Vacancy Details UPSC Engineering Services Examination 2024 :-</h2>
                      
                     <h3><li>Civil Engineering :– Not Specified</li></h3>
                     <h3><li>Mechanical Engineering :– Not Specified</li></h3>
                     <h3><li>Electrical Engineering :– Not Specified</li></h3>
                     <h3><li>Electronics & Telecommunication Engineering :– Not Specified</li></h3>
                     </div>
                </div>
                <div id={style.p6}>
                    <h1>Please read all the information carefully before filling the form</h1>
                </div>
                <div id={style.p7}>
                    <h1>Requirment Document :-</h1>
                    <h3><li>Respective Posts Documents</li></h3>
                    <h3><li>Passport Size Photo</li></h3>
                    <h3><li>Signture</li></h3>
                    <h3><li>Aadhar Card</li></h3>
                    <h3><li>Email Id</li></h3>
                    <h3><li>Phone Number</li></h3>
                </div>
                <div id={style.p8}>
                    <div><h1 style={{color:"blue"}}>Examination Mode :- Offline Base Examination Test</h1></div>
                    <div><h1 style={{color:"green"}}>Here Some Important Links</h1></div>
                </div>
                <div id={style.p9}>
                <div>
                        <div><h2>Check Marks</h2></div>
                        <div><a href="https://upsc.gov.in/sites/default/files/MksRcdCndts-ESEM-24-Engl-281124.pdf" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    <div>
                        <div><h2> Final Result </h2></div>
                        <div><a href="https://upsc.gov.in/sites/default/files/FR-ESEM-24-Engl-221124.pdf" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    <div>
                        <div><h2>DAF Form Apply Online</h2></div>
                        <div><a href="https://upsconline.nic.in/upsc/OTRP/index.php" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    <div>
                        <div><h2> Mains Result</h2></div>
                        <div><a href="https://upsc.gov.in/sites/default/files/ESEM_2024_WR_Eng_30072024.pdf" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    <div>
                        <div><h2>Mains Admit Card</h2></div>
                        <div><a href="https://upsconline.nic.in/eadmitcard/admitcard_esem_2024/admit_card.php" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    <div>
                        <div><h2> Pre Result </h2></div>
                        <div><a href="https://upsc.gov.in/sites/default/files/WR-ESEP-24-engl-Roll-280324.pdf" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    <div>
                        <div><h2>Pre Admit Card</h2></div>
                        <div><a href="https://upsconline.nic.in/eadmitcard/admitcard_esep_2024/" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    <div>
                        <div><h2>Apply Online</h2></div>
                        <div><a href="https://upsconline.nic.in/upsc/OTRP/index.php" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    <div>
                        <div><h2>OTR Registration</h2></div>
                        <div><a href="https://upsconline.nic.in/upsc/OTRP/index.php" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    <div>
                        <div><h2>Official Notification</h2></div>
                        <div><a href="https://upsc.gov.in/sites/default/files/Notif-ESEP-24-engl-060923.pdf" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    <div>
                        <div><h2>Syllabus &Pattern</h2></div>
                        <div><a href="https://testbook.com/upsc-ies/syllabus" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    <div>
                        <div><h2>Privious Year Question</h2></div>
                        <div><a href="https://upsc.gov.in/examinations/previous-question-papers?field_exam_name_value=Engineering+Services" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    <div>
                        <div><h2>How To Apply</h2></div>
                        <div><a href="https://www.youtube.com/watch?v=UfdKN0cyuJk&ab_channel=GyanDevHost" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    <div>
                        <div><h2>Resize Images</h2></div>
                        <div><a href="https://www.iloveimg.com/resize-image" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    <div>
                        <div><h2>JPG To PDF</h2></div>
                        <div><a href="https://www.ilovepdf.com/jpg_to_pdf" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    <div>
                        <div><h2>Official Website</h2></div>
                        <div><a href="https://upsc.gov.in/whats-new" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    <div>
                        <div><h2>Follow Our Pages</h2></div>
                        <div><a href="https://www.facebook.com/profile.php?id=100092973634766" target="_blank"><h2>@Facebook</h2></a><p style={{paddingLeft:"15px"}}></p><a href="https://www.instagram.com/jobforsarkari/" target="_blank"><h2>@Instagram</h2></a></div>
                    </div>
                    <div>
                        <div><h2>Join With Us</h2></div>
                        <div><a href="https://whatsapp.com/channel/0029VatEOgj0wajl9779pA0f" target="_blank"><h2>@Whatsapp</h2></a><p style={{paddingLeft:"15px"}}></p><a href="https://web.telegram.org/a/#-4725277915" target="_blank"><h2>@Telegram</h2></a></div>
                    </div>
                </div>
             </div>
            
        </div>
        <div id={style.ins}>
            <h1>Www.JobForSarkari.Com</h1>
            <h2 style={{color:"green"}}>welcome to our website</h2>
            <p>Our website provide you official site of related job</p>
            <p>Our website does not charge for any service</p>
            <p>We request that you must read all the instructions before filling</p>
        </div>
        </div>
    )
}

export default UPSC_Engineering_Services