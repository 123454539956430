import style from "./000_Content.module.css"
import a1 from "./Pic/Aadhar_1.jpg"
import a2 from "./Pic/Aadhar_2.jpg"

const A0_Aadharcard=()=>{
    document.title="Download Aadhar Card, Check Aadhar Card Status,Modify Aadhar Card Online | Job For Sarkari"
    return(
        <div>
            <div id={style.page}> 
             <h1>Download Aadhar Card, Check Aadhar Card Status,Modify Aadhar Card Online</h1>
             <img src={a1} alt="" id={style.pic}/>
             <h2><b style={{color:"red"}}>Post-Name :-</b> Download Aadhar Card, Check Aadhar Card Status,Modify Aadhar Card Online</h2>
             <h2><b style={{color:"red"}}>Starting Date :- </b>NA</h2>
             <h2><b style={{color:"red"}}>Last Date :- </b>NA</h2>
             <h2 style={{paddingTop:"20px"}}> <b style={{color:"red"}}>Important Information :-</b>UIDAI, the Unique Identification Authority of India, offers Mera Aadhar Meri Pahechaan E Aadhar Card Download Online, Aadhar Card Update/Correction, Enrollment No., and Other Various Information on a Single Portal All Indians are required to enroll in the UIDAI Aadhar Card program through the UIDAI government.</h2>
             <div  id={style.aa} style={{border:"none"}}><h1>Www.JobForSarkari.Com</h1></div>
             
             <div>
                <div id={style.p1}>
                    <h1>UIDAI Download Aadhar Card, Check Aadhar Card Status</h1>
                    <h3>(Unique Identification Authority of India (UIDAI))</h3>
                    <h2>Download Aadhar Card, Check Aadhar Card Status,Modify Aadhar Card Online</h2>
                    
                </div>
                <div id={style.p2}>
                    <h1>Mera Aadhaar, Meri Pehchaan</h1>
                </div>
                <div id={style.p3}>
                    <div>
                        <h1>Important Date</h1>
                        <p><li><b>Scheme Started Date :-</b> 28/01/2009</li></p>
                        <p><li><b>Applying Started Date :-</b> 28/01/2009</li></p>
                        <p><li><b>Last Date For Apply Date :-</b> NA</li></p>
                       
                    </div>
                    <div>
                        <h1>Application Fee</h1>        
                        <p><li><b>New Aadhar Card :-</b> 0/-/-</li></p>   
                        <p><li><b>Demographic update :- </b> 50/-</li></p>  
                        <p><li><b>biometric update :-</b> 100/-/-</li></p>
                        <p style={{paddingTop:"20px"}}> <b style={{color:"red"}}>NOTE :-</b><b> You can pay their Charges / Fee through Credit Card / Debit Card / Net Banking Fee Mode.</b></p> 
                    </div>
                </div>
                <div id={style.p3}>
                <div>
                        <h1>eligible for Aadhaar card</h1>
                        <p><li><b>Nationality :-</b>Indian</li></p>
                        <p><li><b>Nationals of Nepal and Bhutan :-</b>valid passport or other relevant documents</li></p>
                        <p><li><b>NRI :-</b>present in India for at least 182 days in a yea</li></p>
                    </div>
                    <div>
                        <h1>Age Limit <h5 style={{color:"red"}}>Age Limit</h5></h1>  
                        <p><li><b>Minimum Age :-</b>NA</li></p> 
                        <p><li><b>Maximum Age :-</b>NA</li></p> 
                                  
                    </div>
                </div>
                <div id={style.p6}>
                    <h1>Please read all the information carefully before filling the form</h1>
                </div>
                <div id={style.p7}>
                    <h1>Respective Documents Needed For Respective Modification :-</h1>
                    <h3><li>Passport Size Photo</li></h3>
                    <h3><li>Signture</li></h3>
                    <h3><li>Aadhar Card No</li></h3>
                    <h3><li>Email Id</li></h3>
                    <h3><li>Phone Number</li></h3>
                </div>
                <div id={style.p8}>
                    <div><h1 style={{color:"blue"}}>Needed Mobile Or Laptop To Download, Modify Aadhar Card</h1></div>
                    <div><h1 style={{color:"green"}}>Here Some Important Links</h1></div>
                </div>
                <div id={style.p9}>
                    <div>
                        <div><h2>Download Online Aadhar Card</h2></div>
                        <div><a href="https://eaadhaar.uidai.gov.in/" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    <div>
                        <div><h2>Update Aadhar Card Online</h2></div>
                        <div><a href="https://ssup.uidai.gov.in/ssup/login.html" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    <div>
                        <div><h2>Find Enrollment ID / Aadhar No</h2></div>
                        <div><a href="https://resident.uidai.gov.in/lost-uideid" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    <div>
                        <div><h2>Locate Enrollment Center</h2></div>
                        <div><a href="https://appointments.uidai.gov.in/GenericErrorPage.htm?aspxerrorpath=/easearch.aspx" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    <div>
                        <div><h2>Check Aadhar Card Status</h2></div>
                        <div><a href="https://resident.uidai.gov.in/check-aadhaar" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                   
                    <div>
                        <div><h2>How to Download Aadhar Card</h2></div>
                        <div><a href="https://www.youtube.com/watch?v=NdIVh5ye4y8" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    <div>
                        <div><h2>How to Modify Aadhar Card</h2></div>
                        <div><a href="https://www.youtube.com/watch?v=T_J9Bsrb6LI" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    <div>
                        <div><h2>Resize Images</h2></div>
                        <div><a href="https://www.iloveimg.com/resize-image" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    <div>
                        <div><h2>JPG To PDF</h2></div>
                        <div><a href="https://www.ilovepdf.com/jpg_to_pdf" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    <div>
                        <div><h2>Official Website</h2></div>
                        <div><a href="https://uidai.gov.in/" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    <div>
                        <div><h2>Follow Our Pages</h2></div>
                        <div><a href="https://www.facebook.com/profile.php?id=100092973634766" target="_blank"><h2>@Facebook</h2></a><p style={{paddingLeft:"15px"}}></p><a href="https://www.instagram.com/jobforsarkari/" target="_blank"><h2>@Instagram</h2></a></div>
                    </div>
                    <div>
                        <div><h2>Join With Us</h2></div>
                        <div><a href="https://whatsapp.com/channel/0029VatEOgj0wajl9779pA0f" target="_blank"><h2>@Whatsapp</h2></a><p style={{paddingLeft:"15px"}}></p><a href="https://web.telegram.org/a/#-4725277915" target="_blank"><h2>@Telegram</h2></a></div>
                    </div>
                </div>
             </div>
             <img src={a2} alt="" id={style.pic}/>
        </div>
        <div id={style.ins}>
            <h1>Www.JobForSarkari.Com</h1>
            <h2 style={{color:"green"}}>welcome to our website</h2>
            <p>Our website provide you official site of related job</p>
            <p>Our website does not charge for any service</p>
            <p>We request that you must read all the instructions before filling</p>
        </div>
        </div>
    )
}

export default A0_Aadharcard