import style from "./000_Content.module.css"

const Nabard_Assistant_Manager=()=>{
    document.title="Nabard Assistant Manager Grade A Phase II Mains Result | Job For Sarkari"
    return(
        <div>
            <div id={style.page}> 
             <h1>Nabard Assistant Manager Grade A Recruitment, Download Result Phase I OR Phase II Mains Result</h1>
             <h2><b style={{color:"red"}}>Post-Name :-</b> Nabard Assistant Manager Grade A Recruitment 2024</h2>
             <h2><b style={{color:"red"}}>Starting Date :- </b>27/07/2024</h2>
             <h2><b style={{color:"red"}}>Last Date :- </b>15/08/2024</h2>
             <h2 style={{paddingTop:"20px"}}> <b style={{color:"red"}}>Important Information :-</b> Recruitment for Grade A Assistant Manager Various Posts at the National Bank for Agriculture and Rural Development (NABARD) 2024. The Phase II Mains Result is available for applicants interested in the Nabager Grade A Vacancy Assistant Manager AM Examination 2024. To learn about the recruitment eligibility, post details, selection process, vacancy, age restriction, salary range, and other details, read the notice.</h2>
             <div  id={style.aa} style={{border:"none"}}><h1>Www.JobForSarkari.Com</h1></div>
             
             <div>
                <div id={style.p1}>
                    <h1>Nabard Assistant Manager Grade A Online Form 2024</h1>
                    <h3>(National Bank for Agriculture and Rural Development (NABARD))</h3>
                    <h2>Post Name – Nabard Assistant Manager Grade A Recruitment</h2>
                    
                </div>
                <div id={style.p2}>
                    <h1>Total Posts - 102</h1>
                </div>
                <div id={style.p3}>
                    <div>
                        <h1>Important Date</h1>
                        <p><li><b>Start Date :-</b> 27/07/2024</li></p>
                        <p><li><b>Last Date :-</b> 15/08/2024</li></p>
                        <p><li><b>Fee Payment Last Date :-</b> 15/08/2024</li></p>
                        <p><li><b>Phase I Admit Card :-</b> 26/08/2024</li></p>
                        <p><li><b>Phase I Exam Date :-</b>  01/09/2024</li></p>
                        <p><li><b>Phase I Result Date :-</b> 27/09/2024</li></p>
                        <p><li><b>Phase II Admit Card Date :-</b> 09/10/2024</li></p>
                        <p><li><b>Phase II Exam Date :-</b>  20/10/2024</li></p>
                        <p><li><b>Phase II Result Date :-</b> 29/11/2024</li></p>
                    </div>
                    <div>
                        <h1>Application Fee</h1>         
                        <p><li><b>Gen / OBC / EWS :-</b> 850/-</li></p>  
                        <p><li><b>SC / ST / PH :-</b> 150/-</li></p> 
                         
                        <p style={{paddingTop:"20px"}}> <b style={{color:"red"}}>NOTE :-</b><b> Candidates can pay their Exam Fee through Credit Card / Debit Card / Net Banking Fee Mode.</b></p> 
                    </div>
                </div>
                <div id={style.p3}>
                <div>
                        <h1>Job Location</h1>
                        <p style={{padding:"0",textAlign:"center"}}>Any Where In India</p>          
                        
                    </div>
                    <div>
                        <h1>Age Limit  <h5 style={{color:"red"}}>Age Limit till 01/07/2024</h5></h1>  
                         
                        <p><li><b>Minimum Age :-</b> 21 Years</li></p> 
                        <p><li><b>Maximum Age :-</b> 30 Years</li></p>   
                        <p><li>Additional age relaxation as per recruitment guidelines, please read the  official notification.</li></p>            
                    </div>
                </div>
                <div id={style.p5}> <h1 style={{color:"red"}}>Eligibility Qualification Details </h1></div>
                <div className={style.p4}> <div className={style.q1}> <h5>Post Name</h5></div> <div className={style.q2}> <h5>Total Post</h5></div> <div className={style.q3}> <h5>Eligibility Criteria</h5></div></div>
                
                <div className={style.p4}>
                    <div className={style.q1}> Nabard Assistant Manager Grade A</div> 
                    <div className={style.q2}> 102</div> 
                    <div className={style.q3} style={{display:"inline"}}> <li>Bachelor Degree in Related Trade with Minimum 60% Marks.</li> <li> Read the official Notification For Post Wise Eligibility Details .</li></div>
                </div>
                
                <div id={style.p5}>
                    <h1>Vacancy Details for Nabard Assistant Manager Grade A Recruitment (2024) </h1>
                     
                     <div>
                     <h1>Post Name :- Nabard Assistant Manager Grade A Recruitment (2024)</h1>
                     <h2>Post Wise Vacancy Details :-</h2>
                     
                     <h3><li>AM General – 50 Posts</li></h3>
                     <h3><li>Chartered Accountant – 04 Posts</li></h3>
                     <h3><li>Agriculture – 02 Posts</li></h3>
                     <h3><li>Animal Husbandry – 02 Posts</li></h3>
                     <h3><li>Fisheries – 01 Posts</li></h3>
                     <h3><li>Food Processing – 01 Posts</li></h3>
                     <h3><li>Forestry – 02 Posts</li></h3>
                     <h3><li>Plantation & Horticulture – 01 Posts</li></h3>
                     <h3><li>Geo Informatics – 01 Posts</li></h3>
                     <h3><li>Development Management – 03 Posts</li></h3>
                     <h3><li>Computer / IT – 16 Posts</li></h3>
                     <h3><li>Finance – 07 Posts</li></h3>
                     <h3><li>Statistics – 02 Posts</li></h3>
                     <h3><li>Civil Engineering – 03 Posts</li></h3>
                     <h3><li>Electrical Engineering – 01 Posts</li></h3>
                     <h3><li>Environmental Engineering / Science – 1 Posts</li></h3>
                     <h3><li>Human Resource Managementcript Writer – 02 Posts</li></h3>
                     <h3><li>Rajbhasha – 02 Posts</li></h3>
                     
                     </div>
                </div>
                <div id={style.p6}>
                    <h1>Please read all the information carefully before filling the form</h1>
                </div>
                <div id={style.p7}>
                    <h1>Requirment Document :-</h1>
                    <h3><li>Pan Card</li></h3>
                    <h3><li>Respective Posts Documents</li></h3>
                    <h3><li>Passport Size Photo</li></h3>
                    <h3><li>Signture</li></h3>
                    <h3><li>Aadhar Card</li></h3>
                    <h3><li>Email Id</li></h3>
                    <h3><li>Phone Number</li></h3>
                </div>
                <div id={style.p8}>
                    <div><h1 style={{color:"blue"}}>Examination Mode :- Online Computer Base Test(CBT)</h1></div>
                    <div><h1 style={{color:"green"}}>Here Some Important Links</h1></div>
                </div>
                <div id={style.p9}>
                <div>
                        <div><h2>Download Phase II Result</h2></div>
                        <div><a href="https://www.nabard.org/auth/writereaddata/CareerNotices/2911245225Display%20List%202024%20RDBS%20raj.pdf" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    <div>
                        <div><h2>Phase II Admit Card</h2></div>
                        <div><a href="https://ibpsonline.ibps.in/nabardjul24/oecla_sep24/downloadClose.php" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    <div>
                        <div><h2>Phase II Exam Notice</h2></div>
                        <div><a href="https://www.nabard.org/auth/writereaddata/CareerNotices/2709245951Notification%20for%20Mains%20Exam%202024%20English.pdf" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    <div>
                        <div><h2>Download Phase I Result</h2></div>
                        <div><a href="https://doc.sarkariresults.org.in/2709240039Preliminary%20Exam%20Result.pdf" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    <div>
                        <div><h2>Phase I Admit Card</h2></div>
                        <div><a href="https://ibpsonline.ibps.in/nabardjul24/pecla_aug24/downloadClose.php" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    <div>
                        <div><h2>Apply Online</h2></div>
                        <div><a href="https://ibpsonline.ibps.in/nabardjul24/" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    <div>
                        <div><h2>Official Notification</h2></div>
                        <div><a href="https://www.nabard.org/auth/writereaddata/CareerNotices/2707240233final-advertisement-grade-a-rdbs-rajbhasha-2024.pdf" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    <div>
                        <div><h2>Syllabus &Pattern</h2></div>
                        <div><a href="https://www.careerpower.in/nabard-syllabus.html" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    <div>
                        <div><h2>Privious Year Question</h2></div>
                        <div><a href="https://www.adda247.com/jobs/nabard-grade-a-previous-year-question-paper/?srsltid=AfmBOopOc5kNOzWnQrYqs07whbcWqzaXII0lKkqGg-CFBcABcdPs-8fD" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    <div>
                        <div><h2>How To Apply</h2></div>
                        <div><a href="https://www.youtube.com/watch?v=DizMicvWGzw" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    <div>
                        <div><h2>Resize Images</h2></div>
                        <div><a href="https://www.iloveimg.com/resize-image" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    <div>
                        <div><h2>JPG To PDF</h2></div>
                        <div><a href="https://www.ilovepdf.com/jpg_to_pdf" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    <div>
                        <div><h2>Official Website</h2></div>
                        <div><a href="https://www.nabard.org/" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    <div>
                        <div><h2>Follow Our Pages</h2></div>
                        <div><a href="https://www.facebook.com/profile.php?id=100092973634766" target="_blank"><h2>@Facebook</h2></a><p style={{paddingLeft:"15px"}}></p><a href="https://www.instagram.com/jobforsarkari/" target="_blank"><h2>@Instagram</h2></a></div>
                    </div>
                    <div>
                        <div><h2>Join With Us</h2></div>
                        <div><a href="https://whatsapp.com/channel/0029VatEOgj0wajl9779pA0f" target="_blank"><h2>@Whatsapp</h2></a><p style={{paddingLeft:"15px"}}></p><a href="https://web.telegram.org/a/#-4725277915" target="_blank"><h2>@Telegram</h2></a></div>
                    </div>
                </div>
             </div>
            
        </div>
        <div id={style.ins}>
            <h1>Www.JobForSarkari.Com</h1>
            <h2 style={{color:"green"}}>welcome to our website</h2>
            <p>Our website provide you official site of related job</p>
            <p>Our website does not charge for any service</p>
            <p>We request that you must read all the instructions before filling</p>
        </div>
        </div>
    )
}

export default Nabard_Assistant_Manager