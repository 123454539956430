import style from "./000_Content.module.css"

const AIIMS_NORCET=()=>{
    document.title="AIIMS NORCET Admit Card | Job For Sarkari"
    return(
        <div>
            <div id={style.page}> 
             <h1>AIIMS NORCET 6th Recruitment Online Form (2024)</h1>
             <h2><b style={{color:"red"}}>Post-Name :-</b> AIIMS Nursing Officer Recruitment Common Eligibility Test</h2>
             <h2><b style={{color:"red"}}>Starting Date :- </b>26/02/2024</h2>
             <h2><b style={{color:"red"}}>Last Date :- </b>17/03/2024</h2>
             <h2 style={{paddingTop:"20px"}}> <b style={{color:"red"}}>Important Information :-</b>The 6th Combined Recruitment Test for Nursing Officer Post Recruitment at All India Institute of Medical Science (AIIMS) is scheduled for  2024. Candidates interested in applying for the AIIMS Nursing Officer position can download the admit card. To find out about the eligibility for the recruitment, the role, the selection process, the age restriction, the pay scale, and other details, read the announcement.</h2>
             <div  id={style.aa} style={{border:"none"}}><h1>Www.JobForSarkari.Com</h1></div>
             
             <div>
                <div id={style.p1}>
                    <h1>AIIMS Nursing Officer Recruitment Common Eligibility Test (NORCET-2024)</h1>
                    <h3>All India Institute of Medical Science (AIIMS)</h3>
                    <h2>Post Name –  AIIMS Nursing Officer Recruitment Common Eligibility Test</h2>
                    
                </div>
                <div id={style.p2}>
                    <h1>Total Posts - Eligibility Test</h1>
                </div>
                <div id={style.p3}>
                    <div>
                        <h1>Important Date</h1>
                        <p><li><b>Start Date :-</b> 26/02/2024</li></p>
                        <p><li><b>Last Date :-</b> 17/03/2024</li></p>
                        <p><li><b>Fee Payment Last Date :-</b> 17/03/2024</li></p>
                        <p><li><b>Correction Date :-</b>18-20 March 2024</li></p> 
                        <p><li><b>Exam Date Stage I:-</b>14 April 2024</li></p>
                        <p><li><b>Exam Date Stage II:-</b>05 May 2024</li></p>
                        <p><li><b>Admit Card:-</b>12 April 2024</li></p>
                    </div>
                    <div>
                        <h1>Application Fee</h1>  
                        <p><li><b>General / OBC  :-</b> 3000/-</li></p>
                        <p><li><b>SC/ST/EWS :-</b> 2400/-</li></p> 
                        <p><li><b>PH(Disability) :-</b> No Fee</li></p>  
                        <p style={{paddingTop:"20px"}}> <b style={{color:"red"}}>NOTE :-</b><b> Candidates can pay their Exam Fee through Credit Card / Debit Card / Net Banking Fee Mode.</b></p> 
                    </div>
                </div>
                <div id={style.p3}>
                <div>
                        <h1>Job Location</h1>
                        <p style={{padding:"0",textAlign:"center"}}>All Over India</p>          
                        
                    </div>
                    <div>
                        <h1>Age Limit  <h5 style={{color:"red"}}>Age Limit till 15/08/2023</h5></h1>  
                        
                        <p><li><b>Minimum Age :-</b>18 Years</li></p> 
                        <p><li><b>Maximum Age :-</b>30 Years for AIIMS NORCET 2023</li></p> 
                        <p><li><b>Maximum Age :-</b>35 Years for NITRD, New Delhi</li></p> 
                       <p><li>Additional age relaxation as per recruitment guidelines, please read the  official notification.</li></p>            
                    </div>
                </div>
                <div id={style.p5}> <h1 style={{color:"red"}}>Eligibility Qualification Details </h1></div>
                <div className={style.p4}> <div className={style.q1}> <h5>Post Name</h5></div> <div className={style.q2}> <h5>Total Post</h5></div> <div className={style.q3}> <h5>Eligibility Criteria</h5></div></div>
                <div className={style.p4}>
                    <div className={style.q1}> Nursing Officer</div> 
                    <div className={style.q2}> NA</div> 
                    <div className={style.q3} style={{display:"inline"}}> <li>B.Sc. in Nursing and registered with the State / Indian Nursing Council as a nurse and midwife.</li>
                    <li>two years of experience in a 50-bed hospital, the individual holds a diploma in general nursing midwifery and is registered as a nursery and midwife in the State / Nursing Council.</li></div>
                    
                </div>
                
                <div id={style.p5}>
                    <h1>Vacancy Details for AIIMS Norcet 6th Recruitment (2024) </h1>
                     
                     <div>
                     <h1>Post Name :- AIIMS Nursing Officer Recruitment Common Eligibility Test</h1>
                      <h2>State Wise Vacancy Details 2024 :-</h2>
                      
                     <h3><li>Andhra Pradesh :– AIIMS MANGALAGIRI</li></h3>
                     <h3><li>Bihar :– AIIMS Patna</li></h3>
                     <h3><li>Chhattisgarh :– AIIMS RAIPUR</li></h3>
                     <h3><li>Delhi :– AIIMS Delhi</li></h3>
                     <h3><li>Gujarat  :–AIIMS RAJKOT</li></h3>
                     <h3><li>Himachal Pradesh :–AIIMS BILASPUR</li></h3>
                     <h3><li>Jammu & Kashmir :– AIIMS VIJAYPUR</li></h3>
                     <h3><li>Jharkhand :–AIIMS DEOGHAR</li></h3>
                     <h3><li>Madhya Pradesh :– AIIMS BHOPAL</li></h3>
                     <h3><li>Maharashtra :– AIIMS NAGPUR</li></h3>
                     <h3><li>Odisha :– AIIMS  BHUBANESWAR</li></h3>
                     <h3><li>Punjab :– AIIMS BATHINDA</li></h3>
                     <h3><li>Assam :– AIIMS GUWAHATI</li></h3>
                     <h3><li>Telangana :– AIIMS BIBINAGAR</li></h3>
                     <h3><li>Uttarakhand :– AIIMS RISHIKESH</li></h3>
                     <h3><li>Uttar Pradesh :– AIIMS RAE BARELI</li></h3>
                     <h3><li>Uttar Pradesh :– AIIMS GORAKHPUR</li></h3>
                     <h3><li>West Bengal  :– AIIMS GORAKHPUR</li></h3>     
                     </div>
                </div>
                <div id={style.p6}>
                    <h1>Please read all the information carefully before filling the form</h1>
                </div>
                <div id={style.p7}>
                    <h1>Requirment Document :-</h1>
                    <h3><li>ID Proof</li></h3>
                    <h3><li>Passport Size Photo</li></h3>
                    <h3><li>Signture</li></h3>
                    <h3><li>Aadhar Card</li></h3>
                    <h3><li>Email Id</li></h3>
                    <h3><li>Phone Number</li></h3>
                </div>
                <div id={style.p8}>
                    <div><h1 style={{color:"blue"}}>Examination Mode :- Online Computer Base Test(CBT)</h1></div>
                    <div><h1 style={{color:"green"}}>Here Some Important Links</h1></div>
                </div>
                <div id={style.p9}>
                    <div>
                        <div><h2>Admit Card</h2></div>
                        <div><a href="https://norcet6.aiimsexams.ac.in/" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    <div>
                        <div><h2>Apply Online</h2></div>
                        <div><a href="http://norcet5.aiimsexams.ac.in/" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    <div>
                        <div><h2>Official Notification</h2></div>
                        <div><a href="https://docs.aiimsexams.ac.in/sites/Notice%20No.29.pdf" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    <div>
                        <div><h2>Syllabus &Pattern</h2></div>
                        <div><a href="https://www.careerpower.in/ibps-clerk-syllabus-exam-pattern.html" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    <div>
                        <div><h2>Privious Year Question</h2></div>
                        <div><a href="https://www.careerpower.in/ibps-clerk-previous-year-question-paper.html" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    <div>
                        <div><h2>How To Apply</h2></div>
                        <div><a href="https://www.youtube.com/watch?v=fBA8I0pPdog" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    <div>
                        <div><h2>Resize Images</h2></div>
                        <div><a href="https://www.iloveimg.com/resize-image" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    <div>
                        <div><h2>JPG To PDF</h2></div>
                        <div><a href="https://www.ilovepdf.com/jpg_to_pdf" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    <div>
                        <div><h2>Official Website</h2></div>
                        <div><a href="https://www.aiimsexams.ac.in/" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    <div>
                        <div><h2>Follow Our Pages</h2></div>
                        <div><a href="https://www.facebook.com/profile.php?id=100092973634766" target="_blank"><h2>@Facebook</h2></a><p style={{paddingLeft:"15px"}}></p><a href="https://www.instagram.com/jobforsarkari/" target="_blank"><h2>@Instagram</h2></a></div>
                    </div>
                    <div>
                        <div><h2>Join With Us</h2></div>
                        <div><a href="https://whatsapp.com/channel/0029VatEOgj0wajl9779pA0f" target="_blank"><h2>@Whatsapp</h2></a><p style={{paddingLeft:"15px"}}></p><a href="https://web.telegram.org/a/#-4725277915" target="_blank"><h2>@Telegram</h2></a></div>
                    </div>
                   
                </div>
             </div>
            
        </div>
        <div id={style.ins}>
            <h1>Www.JobForSarkari.Com</h1>
            <h2 style={{color:"green"}}>welcome to our website</h2>
            <p>Our website provide you official site of related job</p>
            <p>Our website does not charge for any service</p>
            <p>We request that you must read all the instructions before filling</p>
        </div>
        </div>
    )
}

export default AIIMS_NORCET