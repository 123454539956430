import style from "./000_Content.module.css"
import { Link } from "react-router-dom"
import img from "./Pic/logo2.jpg"
import imga from "./Pic/logo3.jpg"

const A00_Admission_Form=()=>{
    document.title="Admission Form | Job For Sarkari"
    return(
        <div id={style.list}>
            <img src={img} alt="" style={{width:"100%", height:"100px"}} />
        <h1>Admission From</h1>
        <h3>New Job issued on 10 July 2024</h3>
        <li><Link to="/clatugpgentrance" style={{textDecoration:"none"}}> <b className={style.a}>CLAT(Common Law Admission Test) UG / PG Entrance Exam 2025 </b></Link></li>
        <li><Link to="/biharpolytechnic" style={{textDecoration:"none"}}> <b className={style.a}>BIHAR Polytechnic Apply Online Form 2024</b></Link></li>
        <h3>New Job issued on 12 April 2024</h3>
       
        <li><Link to="/biharstet" style={{textDecoration:"none"}}> <b className={style.a}>Bihar State Eligibility Test BSEB STET Apply Online Form for Paper I and II 2024</b></Link></li>
        <li><Link to="/ctet" style={{textDecoration:"none"}}> <b className={style.a}>CTET July 2024 Correction / Edit Form for Paper I & Paper II 2024 </b></Link></li>
        <div><img src={imga} alt="" style={{width:"100%", height:"400px"}} /></div> 
        </div>
    )
}

export default A00_Admission_Form