import style from "./000_Content.module.css"

const RRB_Assistant_Loco_Pilot=()=>{
    document.title="RRB Assistant Loco Pilot Admit Card | Job For Sarkari"
    return(
        <div>
            <div id={style.page}> 
             <h1>RRB (Railway Recruitment Board) Assistant Loco Pilot ALP Recruitment, Admit Card 2024</h1>  
             <h2><b style={{color:"red"}}>Post-Name :-</b>RRB Assistant Loco Pilot ALP Recruitment 2024</h2>
             <h2><b style={{color:"red"}}>Starting Date :- </b>20/01/2024</h2>
             <h2><b style={{color:"red"}}>Last Date :- </b>19/02/2024</h2>
             <h2 style={{paddingTop:"20px"}}> <b style={{color:"red"}}>Important Information :-</b>Assistant Loco Pilot ALP Recruitment 2024 has been released by Indian Railway (Railway Recruitment Board). Candidates may download and check their exam admit cards for the RRB Prayagraj, RRB Gorakhpur, RRB Ajmer, RRB Kolkata, RRB Mumbai, RRB Secunderabad, RRB Chennai, and other recruitments. For more on the recruitment eligibility, post details, selection process, age restriction, salary range, and other details, see the notice.</h2>
             <div  id={style.aa} style={{border:"none"}}><h1>Www.JobForSarkari.Com</h1></div>
             
             <div>
                <div id={style.p1}>
                    <h1>RRB Assistant Loco Pilot ALP Recruitment 2024</h1>
                    <h3>(Railway Recruitment Board (RRB))</h3>
                    <h2>Post Name – RRB Assistant Loco Pilot ALP Recruitment 2024</h2>
                    
                </div>
                <div id={style.p2}>
                    <h1>Total Posts - 18799</h1>
                </div>
                <div id={style.p3}>
                    <div>
                        <h1>Important Date</h1>
                        <p><li><b>Start Date :-</b> 20/01/2024</li></p>
                        <p><li><b>Last Date :-</b> 19/02/2024</li></p>
                        <p><li><b>Fee Payment Last Date :-</b> 19/02/2024</li></p>
                        <p><li><b>Correction / Modified Date :-</b>20-29/02/2024</li></p>
                        <p><li><b>Re Upload Photo / Signature :-</b> 27-31/05/2024</li></p>
                        <p><li><b>Details Zone Wise Revised Vacancy :-</b>  04/07/2024</li></p>
                        <p><li><b>Change Zone / Preferences Online :-</b> 29/07/2024 to 07/08/2024</li></p>
                        <p><li><b>Admit Card :-</b> 21/11/2024</li></p>
                        <p><li><b>Exam Date :-</b> 19-29/12/2024</li></p>
                    </div>
                    <div>
                        <h1>Application Fee</h1>  
                        <p><li><b>General / OBC / EWS :-</b> 500/-</li></p>            
                        <p><li><b>Sc/St :-</b> 250/-</li></p> 
                        <p><li><b>Female(All Category) :-</b> 250/-</li></p>
                        <p><li><b>After Appear the Stage I Exam</b></li></p>
                        <p><li>General / OBC / EWS Fee Refund :- 400/-</li></p>  
                        <p><li>SC / ST / EBC / Female Refund :- 250/-</li></p>
                        <p style={{paddingTop:"20px"}}> <b style={{color:"red"}}>NOTE :-</b><b> Candidates can pay their Exam Fee through Credit Card / Debit Card / Net Banking Fee Mode.</b></p> 
                    </div>
                </div>
                <div id={style.p3}>
                <div>
                        <h1>Job Location</h1>
                        <p style={{padding:"0",textAlign:"center"}}>All Over India</p>          
                        
                    </div>
                    <div>
                        <h1>Age Limit  <h5 style={{color:"red"}}>Age Limit till 01/07/2024</h5></h1>  
                        
                        <p><li><b>Minimum Age :-</b> 18 Years</li></p> 
                        <p><li><b>Maximum Age :-</b> 33 Years</li></p>
                        <p><li>Additional age relaxation as per recruitment guidelines, please read the  official notification.</li></p>            
                    </div>
                </div>
                <div id={style.p5}> <h1 style={{color:"red"}}>Eligibility Qualification Details </h1></div>
                <div className={style.p4}> <div className={style.q1}> <h5>Post Name</h5></div> <div className={style.q2}> <h5>Total Post</h5></div> <div className={style.q3}> <h5>Eligibility Criteria</h5></div></div>
                
                <div className={style.p4}>
                    <div className={style.q1}> Assistant Loco Pilot</div> 
                    <div className={style.q2}> 18799</div> 
                    <div className={style.q3} style={{display:"inline"}}> <li> Class 10th Matric with ITI from NCVT / SCVT Certificate in Fitter, Electrician, Instrument Mechanic, Millwright, Maintenance Mechanic, Mechanic Radio and TV, Electronics Mechanic, Mechanic Motor Vehicle, Wireman, Tractor Mechanic, Armature and Coil Winder, mechanic Diesel, Heat Engine, Turner, Machinist, Refrigeration and AC.</li>
                    <li> Class 10th with Diploma in Mechanical / Electrical / Electronics / Automobile Engineering</li><li> BE / B.Tech Degree in Mechanical / Electrical / Electronics / Automobile Engineering</li><li> More Eligibility Details Read the Notification</li></div>
                </div>
               
                <div id={style.p5}>
                    <h1>Details of the RRB Assistant Loco Pilot ALP Recruitment (2024) </h1>
                     
                     <div>
                     <h1>Post Name :- RRB Assistant Loco Pilot ALP Recruitment 2024</h1>
                     <h2>Cast Wise Vacancy Details 2024 :-</h2>
                     <h3><li>UR :– 8149 Posts</li></h3>
                     <h3><li>OBC :– 4538 Posts</li></h3>
                     <h3><li>EWS :– 1798 Posts</li></h3> 
                     <h3><li>SC :– 2735 Posts</li></h3>
                     <h3><li>ST :– 1579 Posts</li></h3>
                     <h3><li>Total:– 18799 Posts</li></h3> 
                     </div>
                </div>
                <div id={style.p6}>
                    <h1>Please read all the information carefully before filling the form</h1>
                </div>
                <div id={style.p7}>
                    <h1>Requirment Document :-</h1>
                    <h3><li>Respective Posts 10th,12th Documents</li></h3>
                    <h3><li>Passport Size Photo</li></h3>
                    <h3><li>Signture</li></h3>
                    <h3><li>Aadhar Card</li></h3>
                    <h3><li>Email Id</li></h3>
                    <h3><li>Phone Number</li></h3>
                </div>
                <div id={style.p8}>
                    <div><h1 style={{color:"blue"}}>Examination Mode :- Online Computer Base Test(CBT)</h1></div>
                    <div><h1 style={{color:"green"}}>Here Some Important Links</h1></div>
                </div>
                <div id={style.p9}>
                <div>
                        <div><h2>Admit Card</h2></div>
                        <div><a href="https://rrb.digialm.com/EForms/configuredHtml/1181/91195/login.html" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    <div>
                        <div><h2>Apply Online</h2></div>
                        <div><a href="https://www.rrbapply.gov.in/#/auth/home?flag=true" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    <div>
                        <div><h2>Official Notification</h2></div>
                        <div><a href="https://cdn.rojgarresult.com/wp-content/uploads/2024/01/19141127/RRB-ALP-Notification-2024.pdf" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    <div>
                        <div><h2>Syllabus &Pattern</h2></div>
                        <div><a href="https://cdn.rojgarresult.com/wp-content/uploads/2024/01/19145543/RRB-ALP-Syllabus.pdf" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    <div>
                        <div><h2>Privious Year Question</h2></div>
                        <div><a href="https://prepp.in/rpf-si-exam/practice-papers" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    <div>
                        <div><h2>How To Apply</h2></div>
                        <div><a href="https://www.youtube.com/watch?v=IclWO13YK2g&t=197s&ab_channel=SarkariNDNbyJabiRZone" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    <div>
                        <div><h2>Resize Images</h2></div>
                        <div><a href="https://www.iloveimg.com/resize-image" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    <div>
                        <div><h2>JPG To PDF</h2></div>
                        <div><a href="https://www.ilovepdf.com/jpg_to_pdf" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    <div>
                        <div><h2>Official Website</h2></div>
                        <div><a href="https://indianrailways.gov.in/" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    <div>
                        <div><h2>Follow Our Pages</h2></div>
                        <div><a href="https://www.facebook.com/profile.php?id=100092973634766" target="_blank"><h2>@Facebook</h2></a><p style={{paddingLeft:"15px"}}></p><a href="https://www.instagram.com/jobforsarkari/" target="_blank"><h2>@Instagram</h2></a></div>
                    </div>
                    <div>
                        <div><h2>Join With Us</h2></div>
                        <div><a href="https://whatsapp.com/channel/0029VatEOgj0wajl9779pA0f" target="_blank"><h2>@Whatsapp</h2></a><p style={{paddingLeft:"15px"}}></p><a href="https://web.telegram.org/a/#-4725277915" target="_blank"><h2>@Telegram</h2></a></div>
                    </div>
                </div>
             </div>
            
        </div>
        <div id={style.ins}>
            <h1>Www.JobForSarkari.Com</h1>
            <h2 style={{color:"green"}}>welcome to our website</h2>
            <p>Our website provide you official site of related job</p>
            <p>Our website does not charge for any service</p>
            <p>We request that you must read all the instructions before filling</p>
        </div>
        </div>
    )
}

export default RRB_Assistant_Loco_Pilot