import style from "./000_Content.module.css"
import s1 from "./Pic/BSEB_STET_1.jpg"
import s2 from "./Pic/BSEB_STET_2.jpg"

const BIHAR_STET=()=>{
    document.title="BSEB STET Apply Online Form | Job For Sarkari"
    return(
        <div>
            <div id={style.page}> 
             <h1>Bihar State Eligibility Test BSEB STET Apply Online Form for Paper I and II (2024)</h1>
             <img src={s1} alt="" id={style.pic}/>
             <h2><b style={{color:"red"}}>Post-Name :-</b>Bihar State Teacher Eligibility STET Examination 2024</h2>
             <h2><b style={{color:"red"}}>Starting Date :- </b>14/12/2023</h2>
             <h2><b style={{color:"red"}}>Last Date :- </b>02/01/2024</h2>
             <h2 style={{paddingTop:"20px"}}> <b style={{color:"red"}}>Important Information :-</b>An announcement regarding the State Eligibility Test 2024 has been made by the Bihar School Examination Board (BSEB). Online applications for the BSEB STET Paper I and II Exam 2024 are accepted from December 14, 2023, until February 1, 2024. Read the advertising carefully before applying for information on recruitment eligibility, age restriction, post-by-post eligibility, selection process, curriculum, and other details.</h2>
             <div  id={style.aa} style={{border:"none"}}><h1>Www.JobForSarkari.Com</h1></div>
             
             <div>
                <div id={style.p1}>
                    <h1>Bihar State Teacher Eligibility STET Examination (2024)</h1>
                    <h3>(Bihar School Examination Board (BSEB))</h3>
                    <h2>Post Name – Bihar State Teacher Eligibility STET Examination 2024</h2>
                    
                </div>
                <div id={style.p2}>
                    <h1>Total Posts - Eligibility Test</h1>
                </div>
                <div id={style.p3}>
                    <div>
                        <h1>Important Date</h1>
                        <p><li><b>Start Date :-</b> 14/12/2023</li></p>
                        <p><li><b>Last Date :-</b> 02/01/2024</li></p>
                        <p><li><b>Fee Payment Last Date :-</b> 02/01/2024</li></p>
                        <p><li><b>Admit Card :-</b>Available Soon</li></p> 
                        <p><li><b>Exam Date :-</b>As per Schedule</li></p>
                       
                    </div>
                    <div>
                        <h1>Application Fee</h1> 
                        <p style={{color:"red"}}>Single Paper :-</p>
                        <p><li><b>General / OBC / EWS :-</b> 960/-</li></p>
                        <p><li><b>SC/ST/PH :-</b> 760</li></p> 
                        <p style={{color:"red"}}>Both Paper :-</p>
                        <p><li><b>General / OBC / EWS :-</b> 1440/-</li></p>
                        <p><li><b>SC/ST/PH :-</b> 1140</li></p> 
                        
                        <p style={{paddingTop:"20px"}}> <b style={{color:"red"}}>NOTE :-</b><b> Candidates can pay their Exam Fee through Credit Card / Debit Card / Net Banking Fee Mode.</b></p> 
                    </div>
                </div>
                <div id={style.p3}>
                <div>
                        <h1>Job Location</h1>
                        <p style={{padding:"0",textAlign:"center"}}>Bihar</p>          
                        
                    </div>
                    <div>
                        <h1>Age Limit <h5 style={{color:"red"}}>Age Limit till 01/08/2024</h5></h1>  
                        <p><li><b>Minimum Age  :-</b>21 Years</li></p> 
                        <p><li><b>Maximum Age (Male):-</b>37 Years</li></p>
                        <p><li><b>Maximum Age (Female):-</b>40 Years</li></p> 
                       <p><li>Additional age relaxation as per recruitment guidelines, please read the  official notification.</li></p>            
                    </div>
                </div>
                <div id={style.p5}> <h1 style={{color:"red"}}>Eligibility Qualification Details </h1></div>
                <div className={style.p4}> <div className={style.q1}> <h5>Post Name</h5></div> <div className={style.q2}> <h5>Total Post</h5></div> <div className={style.q3}> <h5>Eligibility Criteria</h5></div></div>
                <div className={style.p4}>
                    <div className={style.q1}> Paper 1 (Secondary)</div> 
                    <div className={style.q2}> NA</div> 
                    <div className={style.q3} style={{display:"inline"}}> <li>Bachelor Degree in Related Subject with 50% Marks and B.Ed Exam Passed<p style={{color:"blue"}}>OR</p></li> 
                    <li>Master Degree in Related Subject and B.Ed Exam Passed <p style={{color:"blue"}}>OR</p></li>
                    <li>Bachelor Degree / Master Degree with Minimum 45% Marks (As per NCTE Norms) with B.Ed.<p style={{color:"blue"}}>OR</p></li>
                    <li>4 Year Course BA BEd / BSc BEd Exam Passed <p style={{color:"blue"}}>OR</p></li>
                    <li>For Subject Wise Eligibility Details Read the Notification.</li></div>
                </div>
                <div className={style.p4}>
                    <div className={style.q1}> Paper 2 (Senior Secondary)</div> 
                    <div className={style.q2}> NA</div> 
                    <div className={style.q3} style={{display:"inline"}}><li>Master Degree in Related Subject with 50% Marks and B.Ed Exam / BA BEd / BSc BEd Passed<p style={{color:"blue"}}>OR</p></li> 
                    <li>Master Degree with Minimum 45% Marks (As per NCTE Norms) with B.Ed. <p style={{color:"blue"}}>OR</p></li>
                    <li>Master Degree with 55% Marks and 3 Year B.Ed MEd Course<p style={{color:"blue"}}>OR</p></li>
                    <li>For Subject Wise Eligibility Details Read the Notification.</li></div>
                </div>
                
                <div id={style.p5}>
                    <h1>Details About BSEB STET Online Form (2024) </h1>
                     
                     <div>
                     <h1>Post Name :- Bihar State Teacher Eligibility STET Examination 2024.</h1>
                      <h2>Exam Type of Bihar State Teacher Eligibility STET Examination:-</h2>
                      
                     <h3><li>{`1.)`}Paper 1 (Secondary)</li></h3>
                     <h3><li>{`2.)`}Paper 2 (Senior Secondary)</li></h3>       
                     </div>
                </div>
                <div id={style.p6}>
                    <h1>Please read all the information carefully before filling the form</h1>
                </div>
                <div id={style.p7}>
                    <h1>Requirment Document :-</h1>
                    <h3><li>Matriculation (Class 10th) exam mark sheet and birth certificate</li></h3>
                    <h3><li>Intermediate (Class 12th) certificate and mark sheet</li></h3>
                    <h3><li>Bachelor’s degree certificate and mark sheet</li></h3>
                    <h3><li>Master’s degree certificate and mark sheet</li></h3>
                    <h3><li>B.Ed exam mark sheet and certificate</li></h3>
                    <h3><li>Any other available educational qualifications</li></h3>
                    <h3><li>Category certificate (if applicable)</li></h3>
                    <h3><li>Domicile certificate (if applicable)</li></h3>
                    <h3><li>Photograph of appropriate size with a white or light background</li></h3>
                    <h3><li>Scanned copy of candidate’s signature</li></h3>
                </div>
                <div id={style.p8}>
                    <div><h1 style={{color:"blue"}}>Examination Mode :- Online Computer Base Test(CBT)</h1></div>
                    <div><h1 style={{color:"green"}}>Here Some Important Links</h1></div>
                </div>
                <div id={style.p9}>
                    <div>
                        <div><h2>Apply Online</h2></div>
                        <div><a href="https://www.bsebstet2024.com/login" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    <div>
                        <div><h2>Official Notification</h2></div>
                        <div><a href="https://api.bsebstet2024.com/files/others/stet-notification.pdf" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    <div>
                        <div><h2>Syllabus &Pattern</h2></div>
                        <div><a href="https://www.careerpower.in/blog/bihar-stet-syllabus" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    <div>
                        <div><h2>Privious Year Question</h2></div>
                        <div><a href="https://www.jagranjosh.com/articles/bihar-stet-previous-year-question-paper-with-answers-pdf-download-1692020525-1" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    <div>
                        <div><h2>How To Apply</h2></div>
                        <div><a href="https://www.youtube.com/watch?v=ce5PpjAZNz4&t=13s" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    <div>
                        <div><h2>Resize Images</h2></div>
                        <div><a href="https://www.iloveimg.com/resize-image" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    <div>
                        <div><h2>JPG To PDF</h2></div>
                        <div><a href="https://www.ilovepdf.com/jpg_to_pdf" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    <div>
                        <div><h2>Official Website</h2></div>
                        <div><a href="https://bceceboard.bihar.gov.in/" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    <div>
                        <div><h2>Follow Our Pages</h2></div>
                        <div><a href="https://www.facebook.com/profile.php?id=100092973634766" target="_blank"><h2>@Facebook</h2></a><p style={{paddingLeft:"15px"}}></p><a href="https://www.instagram.com/jobforsarkari/" target="_blank"><h2>@Instagram</h2></a></div>
                    </div>
                    <div>
                        <div><h2>Join With Us</h2></div>
                        <div><a href="https://whatsapp.com/channel/0029VatEOgj0wajl9779pA0f" target="_blank"><h2>@Whatsapp</h2></a><p style={{paddingLeft:"15px"}}></p><a href="https://web.telegram.org/a/#-4725277915" target="_blank"><h2>@Telegram</h2></a></div>
                    </div>
                </div>
             </div>
             <img src={s2} alt="" id={style.pic}/>
        </div>
        
        <div id={style.ins}>
        
            <h1>Www.JobForSarkari.Com</h1>
            <h2 style={{color:"green"}}>welcome to our website</h2>
            <p>Our website provide you official site of related job</p>
            <p>Our website does not charge for any service</p>
            <p>We request that you must read all the instructions before filling</p>
        </div>
        </div>
    )
}

export default BIHAR_STET