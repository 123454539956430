import style from "./000_Content.module.css"

const UPSC_NDA=()=>{
    document.title="UPSC NDA I  Admit Card | Job For Sarkari"
    return(
        <div>
            <div id={style.page}> 
             <h1>UPSC NDA I  Admit Card (2024)</h1>
             <h2><b style={{color:"red"}}>Post-Name :-</b> National Defence Academy & Naval Academy Examination (I) 2024</h2>
             <h2><b style={{color:"red"}}>Starting Date :- </b>20/12/2023</h2>
             <h2><b style={{color:"red"}}>Last Date :- </b>09/01/2024</h2>
             <h2 style={{paddingTop:"20px"}}> <b style={{color:"red"}}>Important Information :-</b>Union Public Service Commission UPSC has released the National Defence Academy & Naval Academy NDA NA Second II Examination 2024. Those candidates who are interested in this UPSC NDA II recruitment can apply online from 17 May 2023 to 06 June 2023. Read the notification for recruitment eligibility, post information, selection procedure, age limit, pay scale and all other information.</h2>
             <div  id={style.aa} style={{border:"none"}}><h1>Www.JobForSarkari.Com</h1></div>
             
             <div>
                <div id={style.p1}>
                    <h1>UPSC NDA II Online Form (2024)</h1>
                    <h3>(Union Public Service Commission(UPSC))</h3>
                    <h2>Post Name – National Defence Academy & Naval Academy Examination (I), 2024</h2>
                    
                </div>
                <div id={style.p2}>
                    <h1>Total Posts - 400</h1>
                </div>
                <div id={style.p3}>
                    <div>
                        <h1>Important Date</h1>
                        <p><li><b>Start Date :-</b> 20/12/2023</li></p>
                        <p><li><b>Last Date :-</b> 09/01/2024</li></p>
                        <p><li><b>Fee Payment Last Date :-</b> 09/01/2024</li></p>
                        <p><li><b>Correction Date :-</b> 10-16 January 2024</li></p> 
                        <p><li><b>Admit Card :-</b> 12/04/2024</li></p>
                        <p><li><b>Exam Date :-</b> 21/04/2024</li></p>
                       
                    </div>
                    <div>
                        <h1>Application Fee</h1>  
                        <p><li><b>General / OBC / EWS :-</b> 100/-</li></p>
                        <p><li><b>SC/ST :-</b> No Fee</li></p> 
                        <p><li><b>PH(Disability) :-</b> No Fee</li></p>  
                        <p style={{paddingTop:"20px"}}> <b style={{color:"red"}}>NOTE :-</b><b> Candidates can pay their Exam Fee through Credit Card / Debit Card / Net Banking Fee Mode.</b></p> 
                    </div>
                </div>
                <div id={style.p3}>
                <div>
                        <h1>Job Location</h1>
                        <p style={{padding:"0",textAlign:"center"}}>All Over India</p>          
                        
                    </div>
                    <div>
                        <h1>Age Limit</h1>  
                        <p><li><b>Candidate Born After :-</b>02/07/2005</li></p> 
                        <p><li><b>Candidate Born Before :-</b>01/07/2008</li></p> 
                       <p><li>Additional age relaxation as per recruitment guidelines, please read the  official notification.</li></p>            
                    </div>
                </div>
                <div id={style.p5}> <h1 style={{color:"red"}}>Eligibility Qualification Details </h1></div>
                <div className={style.p4}> <div className={style.q1}> <h5>Post Name</h5></div> <div className={style.q2}> <h5>Total Post</h5></div> <div className={style.q3}> <h5>Eligibility Criteria</h5></div></div>
                <div className={style.p4}>
                    <div className={style.q1}> Army Wing</div> 
                    <div className={style.q2}> 18</div> 
                    <div className={style.q3} style={{display:"inline"}}> <li>Candidates must have completed their Intermediate or Equivalent Level of Exam from any Recognized Board.</li></div>
                </div>
                <div className={style.p4}>
                    <div className={style.q1}> Air Force & Naval Wing </div> 
                    <div className={style.q2}> 30</div> 
                    <div className={style.q3} style={{display:"inline"}}><li> Candidates must have Completed their Intermediate with Physics & Maths from any recognized Board.</li>
                    <p><b style={{color:"red"}}>NOTE:-</b>Only Unmarried Male Candidates are eligible for Applying NDA.</p></div>
                </div>
                
                <div id={style.p5}>
                    <h1>Vacancy Details for UPSC NDA II Application Form (2024) </h1>
                     
                     <div>
                     <h1>Post Name :- National Defence Academy NDA II (Male / Female) & Naval Academy NA II Only for Male.</h1>
                      <h2>Category Wise Vacancy Details 2024 :-</h2>
                      <h2>National Defence Academy, NDA II :–</h2>
                     <h3><li>Army :– 208 Posts</li></h3>
                     <h3><li>Navy :– 42 Posts</li></h3>
                     <h3><li>Air-Force :– 120 Posts</li></h3>
                     <h2>Naval Academy, NA :–</h2>
                     <h3><li>10+2 Cadet Entry :– 30 Posts</li></h3>
                     
                     </div>
                </div>
                <div id={style.p6}>
                    <h1>Please read all the information carefully before filling the form</h1>
                </div>
                <div id={style.p7}>
                    <h1>Requirment Document :-</h1>
                    <h3><li>Respective Posts 10th&12th Documents</li></h3>
                    <h3><li>Passport Size Photo</li></h3>
                    <h3><li>Signture</li></h3>
                    <h3><li>Aadhar Card</li></h3>
                    <h3><li>Email Id</li></h3>
                    <h3><li>Phone Number</li></h3>
                </div>
                <div id={style.p8}>
                    <div><h1 style={{color:"blue"}}>Examination Mode :- Online Computer Base Test(CBT)</h1></div>
                    <div><h1 style={{color:"green"}}>Here Some Important Links</h1></div>
                </div>
                <div id={style.p9}>
                    <div>
                        <div><h2>Download Admit Card</h2></div>
                        <div><a href="https://upsconline.nic.in/eadmitcard/admitcard_NDAI_2024/admit_card.php#hhh1" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    <div>
                        <div><h2>Apply Online</h2></div>
                        <div><a href="https://upsconline.nic.in/upsc/OTRP/index.php" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    <div>
                        <div><h2>OTR Registration</h2></div>
                        <div><a href="https://upsconline.nic.in/upsc/OTRP/index.php" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    <div>
                        <div><h2>Official Notification</h2></div>
                        <div><a href="https://upsc.gov.in/sites/default/files/Notif-NDA-NA-I-2024-Eng-20122023.pdf" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    <div>
                        <div><h2>Syllabus &Pattern</h2></div>
                        <div><a href="https://www.careerpower.in/nda-syllabus.html" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    <div>
                        <div><h2>Privious Year Question</h2></div>
                        <div><a href="https://www.careerpower.in/nda-previous-year-question-paper.html" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    <div>
                        <div><h2>How To Apply</h2></div>
                        <div><a href="https://www.youtube.com/watch?v=TsBGqMMnAtE&ab_channel=GyanDevHost" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    <div>
                        <div><h2>Resize Images</h2></div>
                        <div><a href="https://www.iloveimg.com/resize-image" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    <div>
                        <div><h2>JPG To PDF</h2></div>
                        <div><a href="https://www.ilovepdf.com/jpg_to_pdf" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    <div>
                        <div><h2>Official Website</h2></div>
                        <div><a href="https://upsc.gov.in/" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    <div>
                        <div><h2>Follow Our Pages</h2></div>
                        <div><a href="https://www.facebook.com/profile.php?id=100092973634766" target="_blank"><h2>@Facebook</h2></a><p style={{paddingLeft:"15px"}}></p><a href="https://www.instagram.com/jobforsarkari/" target="_blank"><h2>@Instagram</h2></a></div>
                    </div>
                    <div>
                        <div><h2>Join With Us</h2></div>
                        <div><a href="https://whatsapp.com/channel/0029VatEOgj0wajl9779pA0f" target="_blank"><h2>@Whatsapp</h2></a><p style={{paddingLeft:"15px"}}></p><a href="https://web.telegram.org/a/#-4725277915" target="_blank"><h2>@Telegram</h2></a></div>
                    </div>
                </div>
             </div>
            
        </div>
        <div id={style.ins}>
            <h1>Www.JobForSarkari.Com</h1>
            <h2 style={{color:"green"}}>welcome to our website</h2>
            <p>Our website provide you official site of related job</p>
            <p>Our website does not charge for any service</p>
            <p>We request that you must read all the instructions before filling</p>
        </div>
        </div>
    )
}

export default UPSC_NDA