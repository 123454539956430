import style from "./000_Content.module.css"

const CTET=()=>{
    document.title="CTET Apply Online Form | Job For Sarkari"
    return(
        <div>
            <div id={style.page}> 
             <h1>CTET July 2024 Correction / Edit Form for Paper I & Paper II 2024</h1>
             <h2><b style={{color:"red"}}>Post-Name :-</b>Central Teacher Eligibility Test CTET July 2024</h2>
             <h2><b style={{color:"red"}}>Starting Date :- </b>07/03/2024</h2>
             <h2><b style={{color:"red"}}>Last Date :- </b>05/04/2024</h2>
             <h2 style={{paddingTop:"20px"}}> <b style={{color:"red"}}>Important Information :-</b>Central Board of Secondary Education (CBSE) has relased imperative instructor Eligibility check CTET July 2024. Those candidates who are interested in this CTET Paper I and Paper II primary and Junior level can apply on-line from 07 March 2024 to 05 April 2024. Read the notification for recruitment eligibility, category clever emptiness, submit information, pet, choice method, age limit, pay scale and all different records.</h2>
             <div  id={style.aa} style={{border:"none"}}><h1>Www.JobForSarkari.Com</h1></div>
             
             <div>
                <div id={style.p1}>
                    <h1>Central Teacher Eligibility Test CTET July (2024)</h1>
                    <h3>(Central Board of Secondary Education (CBSE))</h3>
                    <h2>Post Name – Central Teacher Eligibility Test CTET July 2024</h2>
                    
                </div>
                <div id={style.p2}>
                    <h1>Total Posts - Eligibility Test</h1>
                </div>
                <div id={style.p3}>
                    <div>
                        <h1>Important Date</h1>
                        <p><li><b>Start Date :-</b> 07/03/2024</li></p>
                        <p><li><b>Last Date :-</b> 05/04/2024</li></p>
                        <p><li><b>Fee Payment Last Date :-</b> 05/04/2024</li></p>
                        <p><li><b>Correction / Edit Form :-</b>08-12 April 2024</li></p> 
                        <p><li><b>Admit Card :-</b>Before Exam</li></p> 
                        <p><li><b>Exam Date :-</b>07 July 2024</li></p>
                        <p><li><b>Answer Key Available :-</b>After Exam</li></p>
                        <p><li><b>Result Declared :-</b>Available Soon</li></p>
                    </div>
                    <div>
                        <h1>Application Fee</h1> 
                        <p style={{color:"red"}}>Single Paper :-</p>
                        <p><li><b>General / OBC / EWS :-</b> 1000/-</li></p>
                        <p><li><b>SC/ST/PH :-</b> 500</li></p> 
                        <p style={{color:"red"}}>Both Paper :-</p>
                        <p><li><b>General / OBC / EWS :-</b> 1200/-</li></p>
                        <p><li><b>SC/ST/PH :-</b> 600</li></p> 
                        
                        <p style={{paddingTop:"20px"}}> <b style={{color:"red"}}>NOTE :-</b><b> Candidates can pay their Exam Fee through Credit Card / Debit Card / Net Banking Fee Mode.</b></p> 
                    </div>
                </div>
                <div id={style.p3}>
                <div>
                        <h1>Job Location</h1>
                        <p style={{padding:"0",textAlign:"center"}}>All Over India</p>          
                        
                    </div>
                    <div>
                        <h1>Age Limit</h1>  
                        <p><li><b>Minimum Age  :-</b>18 Years</li></p> 
                        <p><li><b>Maximum Age:-</b>No Restriction</li></p>
                        </div>
                </div>
                <div id={style.p5}> <h1 style={{color:"red"}}>Eligibility Qualification Details </h1></div>
                <div className={style.p4}> <div className={style.q1}> <h5>Post Name</h5></div> <div className={style.q2}> <h5>Total Post</h5></div> <div className={style.q3}> <h5>Eligibility Criteria</h5></div></div>
                <div className={style.p4}>
                    <div className={style.q1}> Paper I(Class I to V)</div> 
                    <div className={style.q2}> NA</div> 
                    <div className={style.q3} style={{display:"inline"}}> <li>Senior Secondary (or its equivalent) with at least 50% marks and passed or appearing in final year of 2- year Diploma in Elementary Education (by whatever name known)<p style={{color:"blue"}}>OR</p></li> 
                    <li>Senior Secondary (or its equivalent) with at least 45% marks and passed or appearing in final year of 2- year Diploma in Elementary Education (by whatever name known), in accordance with the NCTE (Recognition Norms and Procedure), Regulations, 2002. <p style={{color:"blue"}}>OR</p></li>
                    <li>Senior Secondary (or its equivalent) with at least 50% marks and passed or appearing in final year of 4- year Bachelor of Elementary Education (B.El.Ed).<p style={{color:"blue"}}>OR</p></li>
                    <li>Senior Secondary (or its equivalent) with at least 50% marks and passed or appearing in final year of 2- year Diploma in Education (Special Education)*.</li>
                    </div>
                   
                </div>
                <div className={style.p4}>
                <div className={style.q1}> Paper 2 (Class VI to VIII) </div> 
                    <div className={style.q2}> NA</div> 
                    <div className={style.q3} style={{display:"inline"}}> <li>Graduation and passed or appearing in final year of 2-year Diploma in Elementary Education (by whatever name known).<p style={{color:"blue"}}>OR</p></li> 
                    <li>At least 50% marks either in Graduation or in Post-Graduation and passed or appearing in final year Bachelor in Education (B.Ed). <p style={{color:"blue"}}>OR</p></li>
                    <li>Graduation with at least 45% marks and passed or appearing in final year Bachelor in Education (B.Ed), in accordance with the NCTE (Recognition Norms and Procedure) Regulations issued from time to time in this regard.<p style={{color:"blue"}}>OR</p></li>
                    <li>Senior Secondary (or its equivalent) with at least 50% marks and passed or appearing in final year of 4- year Bachelor in Elementary Education (B.El.Ed).<p style={{color:"blue"}}>OR</p></li>
                    <li>Senior Secondary (or its equivalent) with at least 50% marks and passed or appearing in final year of 4- year B.A/B.Sc.Ed or B.A.Ed/B.Sc.Ed.<p style={{color:"blue"}}>OR</p></li> 
                    <li>Graduation with at least 50% marks and passed or appearing in final year B.Ed. (Special Education)*.<p style={{color:"blue"}}>OR</p></li>
                    <li>Any candidate having qualified B.Ed. Programme recognized by the NCTE is eligible to appear in TET/CTET. Moreover, as per the existing TET guidelines circulated vide NCTE letter dated 11-02-2011, a person who is pursuing any of the teacher education courses (recognized by the NCTE or the RCI, as the case may be) specified in the NCTE Notification dated 23rd August 2010 is also qualified to appear in the TET/CTET.<p style={{color:"blue"}}>OR</p></li>
                    <li>Post-Graduation with a minimum 55% marks or equivalent grade and passed or appearing in final year of three-year integrated B.Ed.-M.Ed.</li>
                    </div>
                    </div>
                <div id={style.p5}>
                    <h1>Details About CTET Online Form July (2024) </h1>
                     
                     <div>
                     <h1>Post Name :- Central Teacher Eligibility Test CTET July 2024.</h1>
                      <h2>Exam Type of Central Teacher Eligibility Test CTET Examination:-</h2>
                      
                     <h3><li>{`1.)`}Paper 1 (Class I to V)</li></h3>
                     <h3><li>{`2.)`}Paper 2 (Class VI to VIII)</li></h3>       
                     </div>
                </div>
                <div id={style.p6}>
                    <h1>Please read all the information carefully before filling the form</h1>
                </div>
                <div id={style.p7}>
                    <h1>Requirment Document :-</h1>
                    <h3><li>Matriculation (Class 10th) exam mark sheet and birth certificate</li></h3>
                    <h3><li>Intermediate (Class 12th) certificate and mark sheet</li></h3>
                    <h3><li>Bachelor’s degree certificate and mark sheet</li></h3>
                    <h3><li>Master’s degree certificate and mark sheet</li></h3>
                    <h3><li>B.Ed exam mark sheet and certificate</li></h3>
                    <h3><li>Any other available educational qualifications</li></h3>
                    <h3><li>Category certificate (if applicable)</li></h3>
                    <h3><li>Domicile certificate (if applicable)</li></h3>
                    <h3><li>Photograph of appropriate size with a white or light background</li></h3>
                    <h3><li>Scanned copy of candidate’s signature</li></h3>
                </div>
                <div id={style.p8}>
                    <div><h1 style={{color:"blue"}}>Examination Mode :- Online Computer Base Test(CBT)</h1></div>
                    <div><h1 style={{color:"green"}}>Here Some Important Links</h1></div>
                </div>
                <div id={style.p9}>
                <div>
                        <div><h2>Correction / Edit Form</h2></div>
                        <div><a href="https://examinationservices.nic.in/examsysctet/Root/Home.aspx?enc=Ei4cajBkK1gZSfgr53ImFfEytN2I3LFrLvNrMJcZJNlCCcscwykbysLsSXnWv0wO" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    <div>
                        <div><h2>Download Correction Notice</h2></div>
                        <div><a href="https://cdnbbsr.s3waas.gov.in/s3443dec3062d0286986e21dc0631734c9/uploads/2024/04/2024040892.pdf" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    <div>
                        <div><h2>Apply Online</h2></div>
                        <div><a href="https://examinationservices.nic.in/examsysctet/Root/Home.aspx?enc=Ei4cajBkK1gZSfgr53ImFfEytN2I3LFrLvNrMJcZJNlCCcscwykbysLsSXnWv0wO" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    <div>
                        <div><h2>Official Notification</h2></div>
                        <div><a href="https://cdnbbsr.s3waas.gov.in/s3443dec3062d0286986e21dc0631734c9/uploads/2024/03/2024030749.pdf" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    <div>
                        <div><h2>Syllabus &Pattern</h2></div>
                        <div><a href="https://www.careerpower.in/ctet-syllabus.html" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    <div>
                        <div><h2>Privious Year Question</h2></div>
                        <div><a href="https://ctet.nic.in/archive/" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    <div>
                        <div><h2>How To Apply</h2></div>
                        <div><a href="https://www.youtube.com/watch?v=9RQubCHtWlo&ab_channel=DSHELPINGFOREVER" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    <div>
                        <div><h2>Resize Images</h2></div>
                        <div><a href="https://www.iloveimg.com/resize-image" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    <div>
                        <div><h2>JPG To PDF</h2></div>
                        <div><a href="https://www.ilovepdf.com/jpg_to_pdf" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    <div>
                        <div><h2>Official Website</h2></div>
                        <div><a href="https://ctet.nic.in/" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    <div>
                        <div><h2>Follow Our Pages</h2></div>
                        <div><a href="https://www.facebook.com/profile.php?id=100092973634766" target="_blank"><h2>@Facebook</h2></a><p style={{paddingLeft:"15px"}}></p><a href="https://www.instagram.com/jobforsarkari/" target="_blank"><h2>@Instagram</h2></a></div>
                    </div>
                    <div>
                        <div><h2>Join With Us</h2></div>
                        <div><a href="https://whatsapp.com/channel/0029VatEOgj0wajl9779pA0f" target="_blank"><h2>@Whatsapp</h2></a><p style={{paddingLeft:"15px"}}></p><a href="https://web.telegram.org/a/#-4725277915" target="_blank"><h2>@Telegram</h2></a></div>
                    </div>
                </div>
             </div>
            
        </div>
        <div id={style.ins}>
            <h1>Www.JobForSarkari.Com</h1>
            <h2 style={{color:"green"}}>welcome to our website</h2>
            <p>Our website provide you official site of related job</p>
            <p>Our website does not charge for any service</p>
            <p>We request that you must read all the instructions before filling</p>
        </div>
        </div>
    )
}

export default CTET