import style from "./000_Content.module.css"
import { Link } from "react-router-dom"
import img from "./Pic/logo2.jpg"
import imga from "./Pic/logo3.jpg"
const A00_Document_verification=()=>{
    document.title="Document Verification | Job For Sarkari"
    return(
        <div id={style.list}>
            <img src={img} alt="" style={{width:"100%", height:"100px"}} />
        <h1>Document Verification</h1>
        <h3>New Job issued on 5 April 2023</h3>
        <li><Link to="/aadharcard" style={{textDecoration:"none"}}><b className={style.a}>Download Aadhar Card, Check Aadhar Card Status, Modify Aadhar Card Online</b></Link></li>
        <li><Link to="/voteridcard" style={{textDecoration:"none"}}><b className={style.a}>Apply New Voter ID Registration, Check Application Status, Modify Voter Id Card Online</b></Link></li>
        <li><Link to="/pancard" style={{textDecoration:"none"}}><b className={style.a}>Apply New Pan Card, Download Pan Card, Modification of Pan Card, Link to Aadhar Card Online</b></Link></li>
        <h3>New Job issued on 4 April 2023</h3>
        <li><Link to="/biharrtps" style={{textDecoration:"none"}}><b className={style.a}>Bihar RTPS जाति, निवास, आय Online Form</b></Link></li>
        <li><Link to="/upedistrict" style={{textDecoration:"none"}}><b className={style.a}>Uttar Pradesh eDistrict जाति, निवास, आय Online Form </b></Link></li>
        <div><img src={imga} alt="" style={{width:"100%", height:"400px"}} /></div>
    </div>
    )
}

export default A00_Document_verification