import style from "./000_Content.module.css"

const SSC_GD=()=>{
    document.title="SSC_GD_Constable Online Form | Job For Sarkari"
    return(
        <div>
            <div id={style.page}> 
             <h1> SSC GD Constable Recruitment (2024)</h1>
             <h2><b style={{color:"red"}}>Post-Name :-</b>SSC Constable GD in BSF, CISF, ITBP, CRPF, NCB, SSF, Assam Rifles Recruitment 2023</h2>
             <h2><b style={{color:"red"}}>Starting Date :- </b>24/11/2023</h2>
             <h2><b style={{color:"red"}}>Last Date :- </b>31/12/2023</h2>
             <h2 style={{paddingTop:"20px"}}> <b style={{color:"red"}}>Important Information :-</b>Every year, the Staff Selection Commission, one of the largest recruitment boards in India, holds the GD Constable Examination, to which thousands of applicants apply. Thus, SSC has also released the SSC GD Notification 2023 for the years 2023–2024.The notification states that the deadline to apply online for the SSC GD Constable Recruitment 2023 Exam is December 31, 2023, with online applications opening on November 24, 2023. Additionally, it is anticipated that the test for this recruitment will take place in February or March of 2024; the date has been provided in the notification.</h2>
             <div  id={style.aa} style={{border:"none"}}><h1>Www.JobForSarkari.Com</h1></div>
             
             <div>
                <div id={style.p1}>
                    <h1>SSC GD Constable Recruitment (2023)</h1>
                    <h3>(Staff Selection Commission (SSC))</h3>
                    <h2>Post Name – SSC Constable GD in BSF, CISF, ITBP, CRPF, NCB, SSF, Assam Rifles Recruitment 2023</h2>
                    
                </div>
                <div id={style.p2}>
                    <h1>Total Posts - 26,146</h1>
                </div>
                <div id={style.p3}>
                    <div>
                        <h1>Important Date</h1>
                        <p><li><b>Start Date :-</b> 24/11/2023</li></p>
                        <p><li><b>Last Date :-</b> 31/12/2023</li></p>
                        <p><li><b>Fee Payment Last Date :-</b> 01/01/2024</li></p>
                        <p><li><b>Correction Date :-</b> 04-06 January 2024</li></p> 
                        <p><li><b>Exam Date :-</b> 20/02/2024 To 12/03/2024</li></p>
                        <p><li><b>CDS II Exam Date :-</b> Available Soon</li></p>
                    </div>
                    <div>
                        <h1>Application Fee</h1>  
                        <p><li><b>General / OBC / EWS :-</b> 100/-</li></p>
                        <p><li><b>SC/ST :-</b> No Fee</li></p> 
                        <p><li><b>Female :-</b> No Fee</li></p>
                        <p><li><b>PH(Disability) :-</b> No Fee</li></p>  
                        <p style={{paddingTop:"20px"}}> <b style={{color:"red"}}>NOTE :-</b><b> Candidates can pay their Exam Fee through Credit Card / Debit Card / Net Banking Fee Mode.</b></p> 
                    </div>
                </div>
                <div id={style.p3}>
                <div>
                        <h1>Job Location</h1>
                        <p style={{padding:"0",textAlign:"center"}}>All Over India</p>          
                        
                    </div>
                    <div>
                        <h1>Age Limit  <h5 style={{color:"red"}}>Age Limit till 01/01/2024</h5></h1>  
                        <p><li><b>Minimum Age  :-</b>18 Years</li></p> 
                        <p><li><b>Maximum Age :-</b>23 Years</li></p> 
                       <p><li>Additional age relaxation as per recruitment guidelines, please read the  official notification.</li></p>            
                    </div>
                </div>
                <div id={style.p5}> <h1 style={{color:"red"}}>Eligibility Qualification Details </h1></div>
                <div className={style.p4}> <div className={style.q1}> <h5>Post Name</h5></div> <div className={style.q2}> <h5>Total Post</h5></div> <div className={style.q3}> <h5>Eligibility Criteria</h5></div></div>
                
                <div className={style.p4}>
                    <div className={style.q1}> SSC GD</div> 
                    <div className={style.q2}> 26146</div> 
                    <div className={style.q3}> <li></li>Candidates  should have passed 10th class or equivalent from a recognized Board.</div>
                </div>
                
                    <div id={style.p5}>
                    <h1 style={{color:"green"}}>Physical Details for SSC GD Constable Application Form 2023</h1>
                    <div><h3 style={{color:"red"}}>Height :-</h3>
                    <h3><li><b>Male(General/OBC/SC):-</b> 170cms</li></h3>
                    <h3><b>Male(ST):-</b> 162.5cms</h3>
                    <h3><b>Female(General/OBC/SC):-</b> 157cms</h3>
                    <h3><b>Female(ST):-</b> 150cms</h3>
                    <h3  style={{color:"red"}}>Chest :-</h3>
                    <h3><b>Male(General/OBC/SC):-</b> 80 - 85cms</h3>
                    <h3><b>Male(ST):-</b> 76 - 80cms</h3>
                    <h3><b>Female:-</b> NA</h3>
                    <h3  style={{color:"red"}}>Running :-</h3>
                    <h3><b>Male:-</b> 05 km in 24 minutes</h3>
                    <h3><b>Female:-</b> 05 km in 24 minutes</h3></div>
                    
                    </div>
                <div id={style.p5}>
                    <h1>Vacancy Details for SSC GD Constable Application Form (2023) </h1>
                     
                     <div>
                     <h1>Post Name :- Constables (GD) in CAPFs, NIA, SSF, Rifleman (GD) in Assam Rifles Examination 2023.</h1>
                      <h2>Forces Wise Vacancy Details 2023 :-</h2>
                      
                     <h3><li>BSF :– 6,174 Posts</li></h3>
                     <h3><li>CISF :– 11,025 Posts</li></h3>
                     <h3><li>CRPF :– 3,337 Posts</li></h3>
                     <h3><li>SSB :– 635 Posts</li></h3>
                     <h3><li>ITBP :–3,189 Posts</li></h3>   
                     <h3><li>AR :–1,490 Posts</li></h3>  
                     <h3><li>SSF :–296 Posts</li></h3>      
                     </div>
                </div>
                <div id={style.p6}>
                    <h1>Please read all the information carefully before filling the form</h1>
                </div>
                <div id={style.p7}>
                    <h1>Requirment Document :-</h1>
                    <h3><li>Respective Posts 10th&12th Documents</li></h3>
                    <h3><li>Passport Size Photo</li></h3>
                    <h3><li>Signture</li></h3>
                    <h3><li>Aadhar Card</li></h3>
                    <h3><li>Email Id</li></h3>
                    <h3><li>Phone Number</li></h3>
                </div>
                <div id={style.p8}>
                    <div><h1 style={{color:"blue"}}>Examination Mode :- Online Computer Base Test(CBT)</h1></div>
                    <div><h1 style={{color:"green"}}>Here Some Important Links</h1></div>
                </div>
                <div id={style.p9}>
                    <div>
                        <div><h2>Apply Online</h2></div>
                        <div><a href="https://ssc.nic.in/" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    <div>
                        <div><h2>Download Vacancy Detail</h2></div>
                        <div><a href="https://ssc.nic.in/SSCFileServer/PortalManagement/UploadedFiles/Tentative_Vacancies_15122023.pdf" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    <div>
                        <div><h2>Official Notification</h2></div>
                        <div><a href="https://ssc.nic.in/SSCFileServer/PortalManagement/UploadedFiles/Notice_24112023.pdf" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    <div>
                        <div><h2>Syllabus &Pattern</h2></div>
                        <div><a href="https://www.careerpower.in/ssc-gd-syllabus.html" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    <div>
                        <div><h2>Privious Year Question</h2></div>
                        <div><a href="https://www.careerpower.in/ssc-gd-constable-previous-year-paper.html" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    <div>
                        <div><h2>How To Apply</h2></div>
                        <div><a href="https://www.youtube.com/watch?v=hhVt7UEonaI" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    <div>
                        <div><h2>Resize Images</h2></div>
                        <div><a href="https://www.iloveimg.com/resize-image" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    <div>
                        <div><h2>JPG To PDF</h2></div>
                        <div><a href="https://www.ilovepdf.com/jpg_to_pdf" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    <div>
                        <div><h2>Official Website</h2></div>
                        <div><a href="https://ssc.nic.in/" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    <div>
                        <div><h2>Follow Our Pages</h2></div>
                        <div><a href="https://www.facebook.com/profile.php?id=100092973634766" target="_blank"><h2>@Facebook</h2></a><p style={{paddingLeft:"15px"}}></p><a href="https://www.instagram.com/jobforsarkari/" target="_blank"><h2>@Instagram</h2></a></div>
                    </div>
                    <div>
                        <div><h2>Join With Us</h2></div>
                        <div><a href="https://whatsapp.com/channel/0029VatEOgj0wajl9779pA0f" target="_blank"><h2>@Whatsapp</h2></a><p style={{paddingLeft:"15px"}}></p><a href="https://web.telegram.org/a/#-4725277915" target="_blank"><h2>@Telegram</h2></a></div>
                    </div>
                   
                </div>
             </div>
            
        </div>
        <div id={style.ins}>
            <h1>Www.JobForSarkari.Com</h1>
            <h2 style={{color:"green"}}>welcome to our website</h2>
            <p>Our website provide you official site of related job</p>
            <p>Our website does not charge for any service</p>
            <p>We request that you must read all the instructions before filling</p>
        </div>
        </div>
    )
}

export default SSC_GD