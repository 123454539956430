import { hover } from "@testing-library/user-event/dist/hover"
import style from "./Navcss.module.css"
import { Link } from "react-router-dom"

const Logo1=()=>{
    document.title="Home | Job For Sarkari"
    return(
        <div id={style.logo1}>
            <div>
                <h1>Find Your Job :-</h1>
                
                <div><Link to="/bankjob" style={{textDecoration:"none"}}><p style={{color:"black"}}>Bank Jobs</p></Link></div>
                <div><Link to="/defencejob" style={{textDecoration:"none"}}><p style={{color:"black"}}>Defence Jobs</p></Link></div>
                <div><Link to="/railwayjob" style={{textDecoration:"none"}}><p style={{color:"black"}}>Railway Jobs</p></Link></div>
                <div><Link to="/sscjob" style={{textDecoration:"none"}}><p style={{color:"black"}}>SSC Jobs</p></Link></div>
                <div><Link to="/governmentjob" style={{textDecoration:"none"}}><p style={{color:"black"}}>Goverment Job</p></Link></div>
        
            </div>
            
        </div>
    )
}

export default Logo1