import style from "./000_Content.module.css"
import { Link } from "react-router-dom"
import img from "./Pic/logo2.jpg"
import imga from "./Pic/logo3.jpg"
const A00_Admit_Card=()=>{
    document.title="Admit Card | Job For Sarkari"
    return(
        <div id={style.list}>
            <img src={img} alt="" style={{width:"100%", height:"100px"}} />
        <h1>Admit Card</h1>
        <h3>New Job issued on 26 November 2024</h3>
        <li><Link to="/idbibank" style={{textDecoration:"none"}}><b className={style.a}>IDBI Bank Executive Sales and Operations ESO Recruitment, Admit Card (2024)</b></Link></li>
        <li><Link to="/ibpspomt" style={{textDecoration:"none"}}><b className={style.a}>IBPS Probationary Officer / Management Trainee PO / MT Recruitment 2024, Mains Admit Card</b></Link></li>
          
        <h3>New Job issued on 23 November 2024</h3>
        <li><Link to="/csbcbiharpoliceconstable" style={{textDecoration:"none"}}><b className={style.a}>CSBC Bihar Police Constables Recruitment 2023 Result, PET Admit Card 2024</b></Link></li>
        <h3>New Job issued on 15 November 2024</h3>
        <li><Link to="/rrbassistantlocopilot" style={{textDecoration:"none"}}><b className={style.a}>RRB(Railway Recruitment Board) Assistant Loco Pilot ALP Recruitment, Admit Card 2024</b></Link></li>
        <li><Link to="/clatugpgentrance" style={{textDecoration:"none"}}><b className={style.a}>CLAT(Common Law Admission Test) UG / PG Entrance Exam Admit Card 2025</b></Link></li>
        <h3>New Job issued on 05 November 2024</h3>
        <li><Link to="/indiancoastguardgd" style={{textDecoration:"none"}}><b className={style.a}>Join Indian Coast Guard ICG Yantrik / Navik GD Recruitment, Admit Card 2024</b></Link></li>
                 
        <h3>New Admit Card issued on 12 April 2024</h3>
        <li><Link to="/upsccds" style={{textDecoration:"none"}}><b className={style.a}>UPSC CDS I Admit Card 2024</b></Link></li>
        <li><Link to="/upscnda" style={{textDecoration:"none"}}><b className={style.a}>UPSC NDA I Admit Card 2024</b></Link></li>
        <li><Link to="/aiimsnorcet" style={{textDecoration:"none"}}><b className={style.a}>AIIMS Nursing Officer Admit Card 2024</b></Link></li>   
        
        <h3>New Admit Card issued on 08 December 2023</h3>
        <li><Link to="/uppscstaffnurse" style={{textDecoration:"none"}}><b className={style.a}>UPPSC Staff Nurse Admit Card 2023</b></Link></li> 
        
        <h3>New Admit Card issued on 01 December 2023</h3>
        <li><Link to="/biharpolicebpsscsubinspector" style={{textDecoration:"none"}}><b className={style.a}>Bihar Police BPSSC Sub Inspector Admit Card 2023</b></Link></li>    
        
        <h3>New Admit Card issued on 06 May 2023</h3>
        <li><Link to="/sscmts" style={{textDecoration:"none"}}><b className={style.a}>SSC MTS/Havaldar Admit Card 2023</b></Link></li>
        <div><img src={imga} alt="" style={{width:"100%", height:"400px"}} /></div>
       </div>
    )
}

export default A00_Admit_Card