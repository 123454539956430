import style from "./Navcss.module.css"
import { Link } from "react-router-dom"

const Content1=()=>{
    document.title="Home | Job For Sarkari"
    
    return(
        <div id={style.con1}>
            <div>
                <div> 

                     {/*-------------------------------- New Job ------------------------------------------ */}

                    <h1>New Job</h1>
                    <div id={style.con3}>
                        <ul> 
                        <li><Link to="/railwayrpf"><p>Railway RPF Constable / Sub Inspector Apply Online Form 2024  <b style={{color:"red"}}>New</b> </p></Link></li>
                        <li><Link to="/armytgc"><p>Army TGC 140 January 2025 Batch Apply Online Form 2024  <b style={{color:"red"}}>New</b></p> </Link></li>
                        <li><Link to="/biharpolytechnic"><p>BIHAR Polytechnic Apply Online Form 2024  <b style={{color:"red"}}>New</b></p> </Link></li>
                        <li><Link to="/upsciesiss"><p>UPSC(Union Public Service Commission) IES / ISS Apply Online Form 2024  <b style={{color:"red"}}>New</b></p> </Link></li>
                        <li><Link to="/upsccombinedmedical"><p>UPSC Combined Medical Services Apply Online Form 2024  <b style={{color:"red"}}>New</b></p> </Link></li>
                        <li><Link to="/sscchsl"><p>SSC Combined Higher Secondary Level CHSL 10+2 Examination 2024 Apply Online Form <b style={{color:"red"}}>New</b> </p> </Link></li>
                        <li><Link to="/ctet"><p>CTET July 2024 Correction / Edit Form for Paper I & Paper II 2024  <b style={{color:"red"}}>New</b> </p></Link></li>
                        <li><Link to="/biharstet"><p>Bihar State Eligibility Test BSEB STET Apply Online Form for Paper I and II 2024</p></Link></li>
                        <li><Link to="/sscgd"><p>SSC(Staff Selection Commission) GD Constable Recurment 2023 Online Form</p></Link></li>
                        <li><Link to="/biharpolicebpsscsubinspector"><p>Bihar Police BPSSC Sub Inspector Recurment 2023 Re Upload Photo/Signature</p></Link></li>
                        <li><Link to="/ibpsclerkxii"><p>IBPS(Institute of Banking Personnel Selection) Clerk XIII Recruitment 2023</p></Link></li>
                        <li><Link to="/isrovssc"><p>ISRO VSSC Recruitment Various Post Online Form 2023</p></Link></li>
                        <li><Link to="/ncertnontechnical"><p>NCERT Non Teaching Recruitment  Various Post Online Form 2023</p></Link></li>
                        </ul>   
                    </div>
                    <div id={style.view}><Link to="/newjob" style={{textDecoration:"none"}}><h3 className={style.view1}>See All</h3></Link></div>
                </div>
                <div> 
                    
                     {/*-------------------------------- Admit Card ------------------------------------------ */}

                    <h1>Admit Card</h1>
                    <div id={style.con3}>
                        <ul> 
                        <li><Link to="/idbibank"><p>IDBI Bank Executive Sales and Operations ESO Recruitment, Admit Card  (2024) <b style={{color:"red"}}>New</b></p></Link></li>
                        <li><Link to="/ibpspomt"><p>IBPS Probationary Officer / Management Trainee PO / MT Recruitment 2024, Mains Admit Card <b style={{color:"red"}}>New</b></p> </Link></li>
                        <li><Link to="/csbcbiharpoliceconstable"><p>CSBC Bihar Police Constables Recruitment 2023 Result, PET Admit Card 2024<b style={{color:"red"}}>New</b></p></Link></li>
                        <li><Link to="/rrbassistantlocopilot"><p>RRB (Railway Recruitment Board) Assistant Loco Pilot ALP Recruitment, Admit Card 2024</p></Link></li>
                        <li><Link to="/clatugpgentrance"><p>CLAT (Common Law Admission Test) UG / PG Entrance Exam Admit Card 2025<b style={{color:"red"}}>New</b></p></Link></li>
                        <li><Link to="/indiancoastguardgd"><p> Join Indian Coast Guard ICG Yantrik / Navik GD Recruitment, Admit Card 2024</p></Link></li>
                        <li><Link to="/upsccds"><p>UPSC (Union Public Service Commission) CDS I Admit Card 2024 </p></Link></li>
                        <li><Link to="/upscnda"><p>UPSC (Union Public Service Commission) NDA I Admit Card 2024 </p> </Link></li>
                        <li><Link to="/aiimsnorcet"><p>AIIMS (All India Institute of Medical Sciences) Nursing Officer Admit Card 2024 </p></Link></li>
                        <li><Link to="/uppscstaffnurse"><p>UPPSC Staff Nurse Admit Card 2023</p></Link></li>
                        <li><Link to="/biharpolicebpsscsubinspector"><p>Bihar Police BPSSC Sub Inspector Admit Card 2023 </p></Link></li>
                        <li><Link to="/sscmts"><p>SSC (Staff Selection Commission) MTS/Havaldar Admit Card 2023</p></Link></li>
                        <li><Link to="/allahabadhighcourtresearchassociate"><p>Allahabad High Court Research Associates Recruitment, Download Admit Card 2024 </p></Link></li>
                        </ul>   
                    </div>
                    <div id={style.view}><Link to="/admitcard" style={{textDecoration:"none"}}> <h3 className={style.view1}>See All</h3></Link></div>
                </div>
                <div> 
                    
                     {/*-------------------------------- Result ------------------------------------------ */}

                    <h1>Result</h1>
                    <div id={style.con3}>
                    <ul>
                    <li><Link to="/nabardassistantmanager"><p>Nabard Assistant Manager Grade A Recruitment, Download Result Phase I OR Phase II Mains Result <b style={{color:"red"}}>New</b></p> </Link></li>
                        <li><Link to="/uppscatp"><p>UPPSC Assistant Town Planner ATP Recruitment 2023, Published Final Result (2024) <b style={{color:"red"}}>New</b></p> </Link></li>
                        <li><Link to="/upscengineeringservices"><p>UPSC (Union Public Service Commission) Engineering Services Examination, Final Result and Publish Marks  2024 <b style={{color:"red"}}>New</b></p> </Link></li>
                        <li><Link to="/bpsc0"><p>Bihar BPSC (Bihar Public Service Commission) 69th Pre Exam Recruitment 2023 Final Result 2024 <b style={{color:"red"}}>New</b> </p></Link></li>
                        <li><Link to="/ibpspomt"><p>IBPS Probationary Officer / Management Trainee PO / MT Recruitment, Pre Result with Score Card 2024 <b style={{color:"red"}}>New</b> </p></Link></li>
                        <li><Link to="/irdaiassistantmanager"><p>IRDAI (Insurance Regulatory and Development Authority of India) Assistant Recruitment, Phase I Result (2024) </p></Link></li>
                        <li><Link to="/allahabadhighcourtresearchassociate"><p>Allahabad High Court Research Associates Recruitment 2024 Shortlisted Candidate List <b style={{color:"red"}}>New</b></p></Link></li>
                        
                        <li><Link to="/barc"><p>Bhabha Atomic Research Centre (BARC) Various Posts Result 2023</p> </Link></li>
                        <li><Link to="/barc"><p>SSC ((Staff Selection Commission)) CHSL Tier 1 Download Additional Result 2023</p> </Link></li>
                        <li><Link to="/sscmts"><p>SSC(Staff Selection Commission) MTS/Havaldar Tier I Download Result Final Vacancy Details 2023  </p></Link></li>
                        
                    </ul>   
                    </div>
                    <div id={style.view}><Link to="/result" style={{textDecoration:"none"}}> <h3 className={style.view1}>See All</h3></Link></div>
                </div>
           </div>
           <div>
           <div> 
            
                     {/*-------------------------------- Answer Key ------------------------------------------ */}

                    <h1>Answer Key</h1>
                    <div id={style.con2}>
                        <ul>
                        <li><Link to="/uppscatp"><p>UPPSC Assistant Town Planner ATP Recruitment Answer Key 2024 </p> </Link></li>
                        <li><Link to="/upscengineeringservices"><p>UPSC Engineering Services Examination  Answer Key 2024 </p> </Link></li>
                        <li><Link to="/bpsc0"><p>Bihar BPSC (Bihar Public Service Commission) 69th Answer Key 2024 </p></Link></li>
                        <li><Link to="/ibpspomt"><p>IBPS Probationary Officer / Management Trainee PO / MT Recruitment Answer Key 2024 </p></Link></li>
                        <li><Link to="/irdaiassistantmanager"><p>IRDAI (Insurance Regulatory and Development Authority of India) Assistant Recruitment, Phase I Answer Key 2024 </p></Link></li>
                       
                        </ul>   
                    </div>
                    <div id={style.view}><Link to="/answerkey" style={{textDecoration:"none"}}><h3 className={style.view1}>See All</h3></Link></div>
                </div>
                <div> 
                    
                     {/*-------------------------------- Document Verification ------------------------------------------ */}

                    <h1 style={{paddingLeft:"0px"}}>Document Verification</h1>
                    <div id={style.con2}>
                        <ul>
                        <li><Link to="/aadharcard"><p>Download Aadhar Card, Check Aadhar Card Status, Modify Aadhar Card Online </p></Link></li>
                        <li><Link to="/voteridcard"><p>Apply New Voter ID Registration, Check Application Status, Modify Voter Id Card Online </p></Link></li>
                        <li><Link to="/pancard"><p>Apply New Pan Card, Download Pan Card, Modification of Pan Card, Link to Aadhar Card Online </p></Link></li>
                        <li><Link to="/biharrtps"><p>Bihar RTPS जाति, निवास, आय Online Form </p></Link></li>
                        <li><Link to="/upedistrict"><p>Uttar Pradesh eDistrict जाति, निवास, आय Online Form </p></Link></li>
                        </ul>   
                    </div>
                    <div id={style.view}><Link to="/document" style={{textDecoration:"none"}}><h3 className={style.view1}>See All</h3></Link></div>
                </div>
                <div> 
                    
                     {/*-------------------------------- Admission Form ------------------------------------------ */}

                    <h1>Admission Form</h1>
                    <div id={style.con2}>
                        <ul>
                        <li><Link to="/clatugpgentrance"><p>CLAT(Common Law Admission Test) UG / PG Entrance Exam 2025  <b style={{color:"red"}}>New</b> </p></Link></li>
                        <li><Link to="/biharpolytechnic"><p>BIHAR Polytechnic Apply Online Form 2024  <b style={{color:"red"}}>New</b> </p></Link></li>
                        <li><Link to="/biharstet"><p>Bihar State Eligibility Test BSEB STET Apply Online Form for Paper I and II 2024</p></Link></li>
                        <li><Link to="/ctet"><p>CTET July 2024 Correction / Edit Form for Paper I & Paper II 2024 </p></Link></li>
                        <li><Link to="/clatugpgentrance"><p>CLAT(Common Law Admission Test) UG / PG Entrance Exam 2025 </p></Link></li>
                        <li><Link to="/biharpolytechnic"><p>BIHAR Polytechnic Apply Online Form 2024 </p></Link></li>
                        
                        </ul>   
                    </div>
                    <div id={style.view}><Link to="/admission" style={{textDecoration:"none"}}><h3 className={style.view1}>See All</h3></Link></div>
                </div>
           </div>
           <div>
           <div> 
            
                     {/*-------------------------------- Syllabus ------------------------------------------ */}

                    <h1>Syllabus</h1>
                    <div id={style.con2}>
                        <ul>
                        <li><Link to="/idbibank"><p>IDBI Bank Executive Sales and Operations ESO Syllabus </p></Link></li>
                        <li><Link to="/ibpspomt"><p>IBPS Probationary Officer / Management Trainee PO / MT Recruitment Syllabus</p> </Link></li>
                        <li><Link to="/csbcbiharpoliceconstable"><p>CSBC Bihar Police Constables Recruitment Syllabus</p></Link></li>
                        <li><Link to="/rrbassistantlocopilot"><p>RRB (Railway Recruitment Board) Assistant Loco Pilot ALP Syllabus</p></Link></li>
                        <li><Link to="/clatugpgentrance"><p>CLAT (Common Law Admission Test) UG / PG Entrance Syllabus</p></Link></li>
                        <li><Link to="/indiancoastguardgd"><p> Join Indian Coast Guard ICG Yantrik / Navik GD Syllabus</p></Link></li>
                       
                        
                        </ul>   
                    </div>
                    <div id={style.view}><Link to="/syllabus" style={{textDecoration:"none"}}><h3 className={style.view1}>See All</h3></Link></div>
                </div>
                <div> 
                    
                     {/*-------------------------------- Diploma/IT ------------------------------------------ */}

                    <h1>Diploma/IT</h1>
                    <div id={style.con2}>
                        <ul>
                        <li><Link to="/rrbassistantlocopilot"><p>RRB (Railway Recruitment Board) Assistant Loco Pilot ALP Online Form</p></Link></li>
                            <li><Link to="/uppscstaffnurse"><p>UPPSC Staff Nurse Apply Online Form 2024 </p></Link></li>
                            <li><Link to="/indiancoastguardgd"><p> Join Indian Coast Guard ICG Yantrik / Navik GD Online form 2024</p></Link></li>
                            <li><Link to="/biharpolytechnic"><p>BIHAR Polytechnic Apply Online Form 2024 </p></Link></li>
                            <li><Link to="/biharstet"><p>Bihar State Eligibility Test BSEB STET Apply Online Form for Paper I and II 2024</p></Link></li>
                            <li><Link to="/isrovssc"><p>ISRO VSSC Recruitment Various Post Online Form 2023</p></Link></li>
                        </ul>   
                    </div>
                    <div id={style.view}><Link to="/diploma" style={{textDecoration:"none"}}><h3 className={style.view1}>See All</h3></Link></div>
                </div>
                <div> 
                    
                     {/*-------------------------------- B.Tech/M.Tech ------------------------------------------ */}

                    <h1>B.Tech/M.Tech</h1>
                    <div id={style.con2}>
                        <ul>
                        <li><Link to="/armytgc"><p>Army TGC 140 January 2025 Batch Apply Online Form 2024 </p> </Link></li>
                        <li><Link to="/idbibank"><p>IDBI Bank Executive Sales and Operations ESO Online Form 2024 </p></Link></li>
                        <li><Link to="/upscengineeringservices"><p>UPSC Engineering Services Examination Online Form 2024</p></Link></li>
                        <li><Link to="/irdaiassistantmanager"><p>IRDAI(Insurance Regulatory and Development Authority of India) Assistant online form 2024</p></Link></li>
                        <li><Link to="/railwayrpf"><p>Railway RPF Constable / Sub Inspector Apply Online Form 2024 </p></Link></li>
                        <li><Link to="/biharpolicebpsscsubinspector"><p>Bihar Police BPSSC Sub Inspector online form 2023</p></Link></li>
                        </ul>   
                    </div>
                    <div id={style.view}><Link to="/btech" style={{textDecoration:"none"}}><h3 className={style.view1}>See All</h3></Link></div>
                </div>
           </div>  
        </div>
    )
}

export default Content1