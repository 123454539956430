import style from "./000_Content.module.css"
import { Link } from "react-router-dom"
import img from "./Pic/logo2.jpg"
import imga from "./Pic/logo3.jpg"
const A00_Btech=()=>{
    document.title="B.Tech | Job For Sarkari"
    return(
        <div id={style.list}>
            <img src={img} alt="" style={{width:"100%", height:"100px"}} />
        <h1>B.Tech/M.Tech</h1>
        <h3>New Job issued on 5 April 2024</h3>
        <li><Link to="/armytgc" style={{textDecoration:"none"}}><b className={style.a}>Army TGC 140 January 2025 Batch Apply Online Form 2024</b></Link></li>
        <li><Link to="/idbibank" style={{textDecoration:"none"}}><b className={style.a}>IDBI Bank Executive Sales and Operations ESO Online Form 2024</b></Link></li>
        <li><Link to="/upscengineeringservices" style={{textDecoration:"none"}}><b className={style.a}>UPSC Engineering Services Examination Online Form 2024</b></Link></li>
        <h3>New Job issued on 4 April 2024</h3>
        <li><Link to="/irdaiassistantmanager" style={{textDecoration:"none"}}><b className={style.a}>IRDAI(Insurance Regulatory and Development Authority of India) Assistant online form 2024</b></Link></li>
        <li><Link to="/railwayrpf" style={{textDecoration:"none"}}><b className={style.a}>Railway RPF Constable / Sub Inspector Apply Online Form 2024</b></Link></li>
        <li><Link to="/biharpolicebpsscsubinspector" style={{textDecoration:"none"}}><b className={style.a}>Bihar Police BPSSC Sub Inspector online form 2023</b></Link></li>
        <div><img src={imga} alt="" style={{width:"100%", height:"400px"}} /></div>
    </div>
    )
}

export default A00_Btech