import style from "./000_Content.module.css"
import { Link } from "react-router-dom"
import img from "./Pic/logo2.jpg"
import imga from "./Pic/logo3.jpg"

const A001_Defence_job=()=>{
    document.title="Defence Jobs | Job For Sarkari"
    
    return(
        <div id={style.list}>
            <img src={img} alt="" style={{width:"100%", height:"100px"}} />
        <h1>Defence Jobs</h1>
        <h3>New Job issued on 29 November 2024</h3>
        <li><Link to="/upscengineeringservices" style={{textDecoration:"none"}}><b className={style.a}>UPSC (Union Public Service Commission) Engineering Services Examination, Publish Final Result  2024</b></Link></li>
        
        <h3>New Job issued on 05 November 2024</h3>
        <li><Link to="/indiancoastguardgd" style={{textDecoration:"none"}}><b className={style.a}>Join Indian Coast Guard ICG Yantrik / Navik GD Recruitment, Admit Card 2024</b></Link></li>
        
        <h3>New Job issued on 12 April 2024</h3>
        <li><Link to="/upsciesiss" style={{textDecoration:"none"}}><b className={style.a}>UPSC IES / ISS Apply Online Form 2024</b></Link></li>

        <h3>New Job issued on 10 April 2024</h3>
        <li><Link to="/armytgc" style={{textDecoration:"none"}}><b className={style.a}>Army TGC 140 January 2025 Batch Apply Online Form 2024</b></Link></li>
        <li><Link to="/upsccombinedmedical" style={{textDecoration:"none"}}><b className={style.a}>UPSC Combined Medical Services Apply Online Form 2024</b></Link></li>
        
        <h3>New Job issued on 17 May 2023</h3>
        <li><Link to="/upsccds" style={{textDecoration:"none"}}><b className={style.a}>UPSC CDS II Online Form 2023</b></Link></li>
        <li><Link to="/upscnda" style={{textDecoration:"none"}}><b className={style.a}>UPSC NDA II Online Form 2023</b></Link></li>
        <div><img src={imga} alt="" style={{width:"100%", height:"400px"}} /></div>
        </div>
    )
}
export default A001_Defence_job