import style from "./000_Content.module.css"
import { Link } from "react-router-dom"
import img from "./Pic/logo2.jpg"
import imga from "./Pic/logo3.jpg"

const A001_Ssc_job=()=>{
    document.title="SSC Jobs | Job For Sarkari"
    return(
        <div id={style.list}>
            <img src={img} alt="" style={{width:"100%", height:"100px"}} />
        <h1 id={style.sc}>Ssc Jobs</h1>
        <h3>New Job issued on 07 April 2024</h3>
        <li><Link to="/sscchsl" style={{textDecoration:"none"}}><b className={style.a}>SSC 10+2 CHSL Recruitment Online Form 2023</b></Link></li>
          
        <h3>New Job issued on 24 November 2023</h3>
        <li><Link to="/sscgd" style={{textDecoration:"none"}}><b className={style.a}>SSC GD Contable Recruitment Online Form 2023</b></Link></li>
      
        <h3>New Job issued on 08 December 2023</h3>
        <li><Link to="/sscmts" style={{textDecoration:"none"}}><b className={style.a}>SSC MTS/Havaldar Tier I Result Final Vacancy Details 2023</b></Link></li>
        
        <div><img src={imga} alt="" style={{width:"100%", height:"400px"}} /></div>
        </div>
        
    )
}
export default A001_Ssc_job