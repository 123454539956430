import style from "./000_Content.module.css";

const Allahabad_HighCourt_Research_Associate = () => {
    document.title = "Allahabad High Court Research Associate | Job For Sarkari";
    return(
        <div>
            <div id={style.page}> 
             <h1>Allahabad High Court Research Associates Recruitment 2024 Shortlisted Candidate List / Result</h1>
             <h2><b style={{color:"red"}}>Post-Name :-</b>Allahabad High Court Research Associates Recruitment 2024</h2>
             <h2><b style={{color:"red"}}>Starting Date :- </b>12/08/2024</h2>
             <h2><b style={{color:"red"}}>Last Date :- </b>22/08/2024</h2>
             <h2 style={{paddingTop:"20px"}}> <b style={{color:"red"}}>Important Information :-</b>The recruitment announcement for Research Associates for 2024 has been issued by the Allahabad High Court, AHC Prayagraj. Candidates that are interested in the position can download the results. Read the advertisement to learn more about the Allahabad High Court Research Associates 2024 recruitment, including the requirements, age restriction, qualifications, salary range, and other details, and then submit an application.</h2>
             <div  id={style.aa} style={{border:"none"}}><h1>Www.JobForSarkari.Com</h1></div>
             
             <div>
                <div id={style.p1}>
                    <h1>Allahabad High Court Research Associates Recruitment (2024)</h1>
                    <h3>(High Court of Judicature at Allahabad (Allahabad))</h3>
                    <h2>Post Name –  Research Associates Recruitment</h2>
                    
                </div>
                <div id={style.p2}>
                    <h1>Total Posts - 31</h1>
                </div>
                <div id={style.p3}>
                    <div>
                        <h1>Important Date</h1>
                        <p><li><b>Start Date :-</b> 12/08/2024</li></p>
                        <p><li><b>Last Date :-</b> 22/08/2024</li></p>
                        <p><li><b>Fee Payment Last Date :-</b> 25/08/2024</li></p>
                        <p><li><b> Admit Card :-</b>13/09/2024</li></p>
                        <p><li><b> Exam Date:-</b>28/09/2024</li></p>
                        <p><li><b>Shortlisted Candidate Test & Interview :-</b>13/10/2024</li></p> 
                    </div>
                    <div>
                        <h1>Application Fee</h1>  
                        <p><li><b>General / OBC / EWS /Other State:-</b> 300/-</li></p>
                        <p><li><b>SC/ST :-</b> 300/-</li></p> 
                        <p style={{paddingTop:"20px"}}> <b style={{color:"red"}}>NOTE :-</b><b> Candidates can pay their Exam Fee through Credit Card / Debit Card / Net Banking Fee Mode.</b></p> 
                    </div>
                </div>
                <div id={style.p3}>
                <div>
                        <h1>Job Location</h1>
                        <p style={{padding:"0",textAlign:"center"}}>Allahabad</p>          
                        
                    </div>
                    <div>
                        <h1>Age Limit</h1>  
                        <p><li><b>Minimum Age :-</b>21 Years</li></p> 
                        <p><li><b>Maximum Age :-</b>26 Years for Male</li></p> 
                       <p><li>Age relaxation in accordance with the recruitment guidelines for Allahabad High Court AHC Research Associates, please read the  official notification.</li></p>            
                    </div>
                </div>
                <div id={style.p5}> <h1 style={{color:"red"}}>Eligibility Qualification Details </h1></div>
                <div className={style.p4}> <div className={style.q1}> <h5>Post Name</h5></div> <div className={style.q2}> <h5>Total Post</h5></div> <div className={style.q3}> <h5>Eligibility Criteria</h5></div></div>
                <div className={style.p4}>
                    <div className={style.q1}> Research Associates</div> 
                    <div className={style.q2}> 31</div> 
                    <div className={style.q3} style={{display:"inline"}}> <li>Bachelor Degree in Law (LLB 3 Years / 5 Years) from Any Recognized University in India.</li>
                    <li>LLB Final Year Appearing Candidate Also Eligible.</li></div>
                    
                </div>
                <div id={style.p5}>
                    <h1>Vacancy Details for Allahabad High Court Research Associates Recruitment (2024) </h1>
                     
                     <div>
                     <h1>Post Name :- Research Associates Recruitment (2024).</h1>
                      <h2>Vacancy Details 2024 :-</h2>
                      
                      <h3><li>Research Associates :– 31 Posts</li></h3>
                     </div>
                </div>
               
                <div id={style.p6}>
                    <h1>Please read all the information carefully before filling the form</h1>
                </div>
                <div id={style.p7}>
                    <h1>Requirment Document :-</h1>
                    <h3><li>ID Proof</li></h3>
                    <h3><li>Passport Size Photo</li></h3>
                    <h3><li>Signture</li></h3>
                    <h3><li>Aadhar Card</li></h3>
                    <h3><li>Email Id</li></h3>
                    <h3><li>Phone Number</li></h3>
                </div>
                <div id={style.p8}>
                    <div><h1 style={{color:"blue"}}>Examination Mode :- Online Computer Base Test(CBT)</h1></div>
                    <div><h1 style={{color:"green"}}>Here Some Important Links</h1></div>
                </div>
                <div id={style.p9}>
                    <div>
                        <div><h2>Download Result/Merit List</h2></div>
                        <div><a href="https://doc.sarkariresults.org.in/AHC_RO__SarkariResult_Com_List_of_71_Candidates_25-10-24.pdf" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    <div>
                        <div><h2>Download Admit Card</h2></div>
                        <div><a href="https://doc.sarkariresults.org.in/AHC_RO__SarkariResult_Com_List_of_71_Candidates_25-10-24.pdf" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    <div>
                        <div><h2>Apply Online</h2></div>
                        <div><a href="https://apps2.allahabadhighcourt.in/recruit/LawClerk2024/index.jsp" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    
                    <div>
                        <div><h2>Official Notification</h2></div>
                        <div><a href="https://doc.sarkariresults.org.in/SarkariResult_Com_AHC_RO_event_19458_07-08-2024.pdf" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    <div>
                        <div><h2>Syllabus &Pattern</h2></div>
                        <div><a href="https://testbook.com/allahabad-high-court-research-associate/syllabus-exam-pattern" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    <div>
                        <div><h2>Privious Year Question</h2></div>
                        <div><a href="https://www.careerpower.in/blog/allahabad-high-court-previous-year-question-paper" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    <div>
                        <div><h2>How To Apply</h2></div>
                        <div><a href="https://www.youtube.com/watch?v=Yu-RZi7oZs0" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    <div>
                        <div><h2>Resize Images</h2></div>
                        <div><a href="https://www.iloveimg.com/resize-image" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    <div>
                        <div><h2>JPG To PDF</h2></div>
                        <div><a href="https://www.ilovepdf.com/jpg_to_pdf" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    <div>
                        <div><h2>Official Website</h2></div>
                        <div><a href="https://www.allahabadhighcourt.in/" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    <div>
                        <div><h2>Follow Our Pages</h2></div>
                        <div><a href="https://www.facebook.com/profile.php?id=100092973634766" target="_blank"><h2>@Facebook</h2></a><p style={{paddingLeft:"15px"}}></p><a href="https://www.instagram.com/jobforsarkari/" target="_blank"><h2>@Instagram</h2></a></div>
                    </div>
                    <div>
                        <div><h2>Join With Us</h2></div>
                        <div><a href="https://whatsapp.com/channel/0029VatEOgj0wajl9779pA0f" target="_blank"><h2>@Whatsapp</h2></a><p style={{paddingLeft:"15px"}}></p><a href="https://web.telegram.org/a/#-4725277915" target="_blank"><h2>@Telegram</h2></a></div>
                    </div>
                   
                </div>
             </div>
            
        </div>
        <div id={style.ins}>
            <h1>Www.JobForSarkari.Com</h1>
            <h2 style={{color:"green"}}>welcome to our website</h2>
            <p>Our website provide you official site of related job</p>
            <p>Our website does not charge for any service</p>
            <p>We request that you must read all the instructions before filling</p>
        </div>
        </div>
    )
}

export default Allahabad_HighCourt_Research_Associate
