import style from "./Navcss.module.css"
import { Link } from "react-router-dom"

const Nav=()=>{
    return(
        <div id={style.nav}>
           <div className={style.n1}></div>
        
            <div id={style.n2}>
            <div><Link style={{textDecoration:"none"}} to="/"><h3 className={style.na}>Home</h3></Link></div> 
            <div><Link style={{textDecoration:"none"}} to="/newjob"><h3 className={style.na}>New Job</h3></Link></div>
            <div><Link style={{textDecoration:"none"}} to="/admitcard"><h3 className={style.na} Link >Admit Card</h3></Link></div>
            <div><Link style={{textDecoration:"none"}} to="/result"><h3 className={style.na}>Result</h3></Link></div>
            <div><Link style={{textDecoration:"none"}} to="/answerkey"><h3 className={style.na} >Answer Key</h3></Link></div>
            <div><Link style={{textDecoration:"none"}} to="/syllabus"><h3 className={style.na}>Syllabus</h3></Link></div>
            <div><Link style={{textDecoration:"none"}} to="/btech"><h3 className={style.na}>B.Tech</h3></Link></div>
            </div>
           <div className={style.n1}></div>
        
    </div>
       
    )
}

export default Nav