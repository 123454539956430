import style from "./000_Content.module.css"

const Indian_Coast_Guard_GD=()=>{
    document.title="Indian_Coast_Guard_GD | Job For Sarkari"
    return(
        <div>
            <div id={style.page}> 
             <h1>Join Indian Coast Guard ICG Yantrik / Navik GD Recruitment, Admit Card (2024)</h1>
             <h2><b style={{color:"red"}}>Post-Name :-</b> Join Indian Coast Guard ICG Yantrik / Navik GD Recruitment 2024</h2>
             <h2><b style={{color:"red"}}>Starting Date :- </b>03/06/2024</h2>
             <h2><b style={{color:"red"}}>Last Date :- </b>10/07/2024</h2>
             <h2 style={{paddingTop:"20px"}}> <b style={{color:"red"}}>Important Information :-</b>The recruitment advertising for Yantrik (Electrical, Mechanical, Electroncis) and Navik General Duty GD CGEPT 01/2025 Batch 2024 has been issued by the Indian Coast Guard (ICG). All applicants may get their admit cards and check the exam city and date for the Coast Guard Yantrik/Navik Recruitment 2024. For further information on the recruiting process, including the age limit, qualifications, curriculum, and selection process, see the advertising.</h2>
             <div  id={style.aa} style={{border:"none"}}><h1>Www.JobForSarkari.Com</h1></div>
             
             <div>
                <div id={style.p1}>
                    <h1>Indian Coast Guard Yantrik / Navik GD Online Form (2024)</h1>
                    <h3>(Join Indian Coast Guard(ICG))</h3>
                    <h2>Post Name – Join Indian Coast Guard Yantrik / Navik GD Recruitment (2024)</h2>
                    
                </div>
                <div id={style.p2}>
                    <h1>Total Posts - 320</h1>
                </div>
                <div id={style.p3}>
                    <div>
                        <h1>Important Date</h1>
                        <p><li><b>Start Date :-</b> 03/06/2024</li></p>
                        <p><li><b>Last Date :-</b> 10/07/2024</li></p>
                        <p><li><b>Exam City Available :-</b> 12/11/2024</li></p>
                        <p><li><b>Admit Card :-</b> 20/11/2024</li></p>
                        <p><li><b>Stage I Exam Date :-</b> 23-24 November 2024</li></p> 
                        <p><li><b>Stage II Exam Date :-</b> end December 2024</li></p> 
                        <p><li><b>Stage III Exam Date :-</b> mid April 2025</li></p> 
                    </div>
                    <div>
                        <h1>Application Fee</h1>  
                        <p><li><b>General / OBC / EWS :-</b> 300/-</li></p>
                        <p><li><b>SC/ST :-</b> 0/-</li></p>
                        <p style={{paddingTop:"20px"}}> <b style={{color:"red"}}>NOTE :-</b><b> Candidates can pay their Exam Fee through Credit Card / Debit Card / Net Banking Fee Mode.</b></p> 
                    </div>
                </div>
                <div id={style.p3}>
                <div>
                        <h1>Job Location</h1>
                        <p style={{padding:"0",textAlign:"center"}}>All Over India</p>          
                        
                    </div>
                    <div>
                        <h1>Age Limit</h1>  
                        <p><li><b>Minimum Age :-</b>18 Years</li></p> 
                        <p><li><b>Maximum Age :-</b>22 Years</li></p> 
                       <p><li>Additional Age Relaxation Per Join Indian Coast Guard(ICG) Hiring Guidelines, please read the  official notification.</li></p>            
                    </div>
                </div>
                <div id={style.p5}> <h1 style={{color:"red"}}>Eligibility Qualification Details </h1></div>
                <div className={style.p4}> <div className={style.q1}> <h5>Post Name</h5></div> <div className={style.q2}> <h5>Total Post</h5></div> <div className={style.q3}> <h5>Eligibility Criteria</h5></div></div>
                <div className={style.p4}>
                    <div className={style.q1}> Navik General Duty GD</div> 
                    <div className={style.q2}> 260</div> 
                    <div className={style.q3}> <li></li> 10+2 Intermediate Exam with Physics / Mathematics as a Subject.</div>
                </div>
                <div className={style.p4}>
                <div className={style.q1}> Yantrik</div> 
                    <div className={style.q2}> 60</div> 
                    <div className={style.q3} > <li></li> Class 10 with Engineering Diploma in Electrical / Mechanical / Electronics and Telecommunication.</div>
                    </div>  
                <div id={style.p5}>
                    <h1>Join Indian Coast Guard Yantrik / Navik GD Recruitment (2024) </h1>
                     
                     <div>
                     <h1>Post Name :- Join Indian Coast Guard Yantrik / Navik GD Recruitment</h1>
                     <h2>Post Wise Vacancy Details 2024 :-</h2>
                     <h3><li>{`1.)`}Navik General Duty GD :– 260  Posts</li></h3>
                     <h3><li>{`2.)`}Yantrik Mechanical :– 33 Posts</li></h3>
                     <h3><li>{`2.)`}Yantrik Electrical :– 18 Posts</li></h3>
                     <h3><li>{`2.)`}Yantrik Electronics :– 09 Posts</li></h3>
                     </div>
                </div>
                <div id={style.p6}>
                    <h1>Please read all the information carefully before filling the form</h1>
                </div>
                <div id={style.p7}>
                <h1>Requirment Document :-</h1>
                    <h3><li>Respective Posts Documents</li></h3>
                    <h3><li>Passport Size Photo</li></h3>
                    <h3><li>Respective ID Proof</li></h3>
                    <h3><li>Signture</li></h3>
                    <h3><li>Aadhar Card</li></h3>
                    <h3><li>Email Id</li></h3>
                    <h3><li>Phone Number</li></h3>
                </div>
                <div id={style.p8}>
                    <div><h1 style={{color:"blue"}}>Examination Mode :- Online Computer Base Test(CBT)</h1></div>
                    <div><h1 style={{color:"green"}}>Here Some Important Links</h1></div>
                </div>
                <div id={style.p9}>
                    <div>
                        <div><h2>Admit Card</h2></div>
                        <div><a href="hhttps://cgept.cdac.in/icgreg/candidate/login" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    <div>
                        <div><h2>Apply Online</h2></div>
                        <div><a href="https://cgept.cdac.in/icgreg/candidate/login" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    
                    <div>
                        <div><h2>Official Notification</h2></div>
                        <div><a href="https://www.sarkariresults.org.in/wp-content/uploads/2024/06/sarkariresult.com-CGEPT01-2025adv-PDF.pdf" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    <div>
                        <div><h2>Syllabus &Pattern</h2></div>
                        <div><a href="https://joinindiancoastguard.cdac.in/cgept/careerOpportunity/navik/gd" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    <div>
                        <div><h2>Privious Year Question</h2></div>
                        <div><a href="https://testbook.com/idbi-executive/previous-year-papers" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    <div>
                        <div><h2>How To Apply</h2></div>
                        <div><a href="https://www.youtube.com/watch?v=BUEfkyQXWvk" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    <div>
                        <div><h2>Resize Images</h2></div>
                        <div><a href="https://www.iloveimg.com/resize-image" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    <div>
                        <div><h2>JPG To PDF</h2></div>
                        <div><a href="https://www.ilovepdf.com/jpg_to_pdf" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    <div>
                        <div><h2>Official Website</h2></div>
                        <div><a href="https://indiancoastguard.gov.in/" target="_blank"><h2>Click Here</h2></a></div>
                    </div>
                    <div>
                        <div><h2>Follow Our Pages</h2></div>
                        <div><a href="https://www.facebook.com/profile.php?id=100092973634766" target="_blank"><h2>@Facebook</h2></a><p style={{paddingLeft:"15px"}}></p><a href="https://www.instagram.com/jobforsarkari/" target="_blank"><h2>@Instagram</h2></a></div>
                    </div>
                    <div>
                        <div><h2>Join With Us</h2></div>
                        <div><a href="https://whatsapp.com/channel/0029VatEOgj0wajl9779pA0f" target="_blank"><h2>@Whatsapp</h2></a><p style={{paddingLeft:"15px"}}></p><a href="https://web.telegram.org/a/#-4725277915" target="_blank"><h2>@Telegram</h2></a></div>
                    </div>
                </div>
             </div>
            
        </div>
        <div id={style.ins}>
            <h1>Www.JobForSarkari.Com</h1>
            <h2 style={{color:"green"}}>welcome to our website</h2>
            <p>Our website provide you official site of related job</p>
            <p>Our website does not charge for any service</p>
            <p>We request that you must read all the instructions before filling</p>
        </div>
        </div>
    )
}

export default Indian_Coast_Guard_GD