import style from "./000_Content.module.css"
import { Link } from "react-router-dom"
import img from "./Pic/logo2.jpg"
import imga from "./Pic/logo3.jpg"
const A00_Answer_Key=()=>{
    document.title="Answer Key | Job For Sarkari"
    return(
        <div id={style.list}>
            <img src={img} alt="" style={{width:"100%", height:"100px"}} />
        <h1>Answer Key</h1>
        <h3>New Job issued on 5 April 2023</h3>
        <li><Link to="/uppscatp" style={{textDecoration:"none"}}><b className={style.a}>UPPSC Assistant Town Planner ATP Recruitment Answer Key 2024 </b></Link></li>
        <li><Link to="/upscengineeringservices" style={{textDecoration:"none"}}><b className={style.a}>UPSC Engineering Services Examination  Answer Key 2024</b></Link></li>
        <li><Link to="/bpsc0" style={{textDecoration:"none"}}><b className={style.a}>Bihar BPSC (Bihar Public Service Commission) 69th Answer Key 2024 </b></Link></li>
        <h3>New Job issued on 4 April 2023</h3>
        <li><Link to="/ibpspomt" style={{textDecoration:"none"}}><b className={style.a}>IBPS Probationary Officer / Management Trainee PO / MT Recruitment Answer Key 2024 </b></Link></li>
        <li><Link to="/irdaiassistantmanager" style={{textDecoration:"none"}}><b className={style.a}>IRDAI (Insurance Regulatory and Development Authority of India) Assistant Recruitment, Phase I Answer Key 2024</b></Link></li>
        <div><img src={imga} alt="" style={{width:"100%", height:"400px"}} /></div>
    </div>
    )
}

export default A00_Answer_Key